.time-execution-chart {
	&__title {
		margin: 16px 0;
		@include font-base-20($black, true);
	}

	& > div svg {
		margin-left: -1px;
		margin-bottom: -3px;
		overflow: visible;
		overflow-clip-margin: unset;
	}

	& > div {
		border: 1px solid $n-90;
		border-radius: 8px;
		border-bottom: none;
		overflow: visible !important;
	}

	.highcharts-plot-background {
		fill: $white;
		overflow: hidden;
		border-bottom-right-radius: 6px;
	}

	.highcharts-axis-line {
		stroke: $n-90;

		&:first-of-type {
			display: none;
		}

		&:last-of-type {
			display: none;
		}
	}

	.highcharts-scrolling {
		border-radius: 8px;
	}

	.highcharts-scrolling-parent {
		border-radius: 8px;

		.highcharts-background {
			height: 0;
		}
	}

	.highcharts-tick {
		stroke: $n-90;
	}

	.highcharts-xaxis-labels {
		span {
			padding-left: 4px;
		}
	}

	.highcharts-fixed {
		border-radius: 8px;
	}

	.highcharts-xaxis-labels.highcharts-grid-axis {
		height: 24px;
		display: flex;
		align-items: center;
		border-bottom: none;
	}

	.highcharts-point .highcharts-partfill-original {
		height: 16px;
	}

	.highcharts-treegrid-node-level-1,
	.highcharts-treegrid-node-level-2 {
		font-size: 14px !important;
		font-weight: 400 !important;
		color: $n-17 !important;
	}
	.highcharts-treegrid-node-expanded,
	.highcharts-treegrid-node-collapsed {
		font-weight: bold !important;
		text-decoration: none !important;
	}
}
