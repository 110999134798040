.report-card {
	position: relative;
	padding: 16px;
	width: 17%;
	border: 2px solid $n-90;
	border-right: none;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&:first-of-type {
		border-radius: 6px 0 0 6px;
	}

	&:last-of-type {
		border-right: 2px solid $n-90;
		border-radius: 0 6px 6px 0;
	}

	&__title-wrapper {
		margin: 0 0 4px;
		display: flex;
		align-items: center;
		@include font-base-10($n-35, true);

		.report-card__title {
			text-transform: uppercase;
		}

		svg {
			display: flex;
			margin-left: 4px;
			width: 12px;
			height: 12px;
		}

		.tooltip {
			.multi-line {
				text-align: center !important;
				text-transform: none;
			}

			.multi-line + .multi-line {
				margin-top: 8px;
			}
		}
	}

	&__value {
		position: relative;
		@include font-base-14($n-17);
	}

	&__tooltip {
		position: absolute;
		top: -37px;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 16px;
		border-radius: 10px;
		display: none;
		text-align: center;
		background-color: $n-17;
		color: $white;
		transition: 0.5s;
		z-index: 99;

		&::before {
			@include pseudo();
			bottom: -6px;
			left: calc(50% - 5px);
			width: 0;
			height: 0;
			border-top: 6px solid $n-17;
			border-right: 5px solid transparent;
			border-left: 5px solid transparent;
		}
	}

	&--status {
		.report-card__title-wrapper {
			font-weight: 700;
			color: $n-17;
		}

		.report-card__value {
			padding-left: 28px;
			font-weight: 700;
		}

		.report-card__status-icon {
			position: absolute;
			bottom: -4px;
			left: -2px;
			margin: 0 0 2px 2px;
			width: 24px;
			height: 24px;
			display: block;
		}
	}

	&--truncated {
		&:hover {
			.report-card__tooltip {
				display: block;
			}
		}
	}

	&--compute-unit {
		.report-card__value {
			text-transform: uppercase;
		}
	}
}
