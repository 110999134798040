.dropdown-filter {
	margin-right: 24px;
	width: 147px;
	user-select: none;

	&:last-of-type {
		margin-right: 0;
	}

	&--disabled {
		@include font-base-14($n-75);
		cursor: not-allowed;

		.selected-item {
			border: 1px solid $n-90;
			pointer-events: none;

			&:after {
				border-top: 4px solid $n-75;
			}
		}
	}

	&__selected-item {
		position: relative;
		padding: 6px 28px 0 12px;
		height: 100%;
		min-height: 32px;
		width: 150px;
		border: 1px solid $n-75;
		border-radius: 4px;
		font-size: 14px;
		cursor: pointer;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&::first-letter {
			text-transform: capitalize;
		}

		&:after {
			@include pseudo();
			top: 50%;
			transform: translate(0, -50%);
			right: 12px;
			width: 0;
			height: 0;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-top: 4px solid $n-35;
		}

		&:hover {
			border: 1px solid $n-75;
		}
	}

	&__items {
		position: absolute;
		border-radius: 4px;
		box-shadow: 0 3px 8px 0 rgba(106, 109, 138, 0.4);
		display: inline-block;
		background: $white;
		min-width: 150px;
		transform: translateY(8px);
		z-index: 1;
	}

	&__item {
		padding: 4px 12px;
		margin-top: 2px;
		border-radius: 4px;
		@include font-base-14($n-17, true);
		letter-spacing: 0;
		cursor: pointer;

		&:first-of-type {
			margin-top: 0;
		}

		&:hover,
		&.selected {
			background: $n-95;
		}
	}

	&__line {
		height: 1px;
		border: 1px solid $n-95;
	}

	&__clear {
		padding: 8px;

		.dropdown-filter__item {
			padding: 10px 9px 10px 22px;
		}
	}

	&__controls {
		padding: 8px;

		.dropdown-filter__item--active {
			background: #eff0f6;
		}
	}

	.dropdown-checkbox {
		input[type="checkbox"]:checked ~ label::before {
			border: 2px solid $n-35;
		}

		label {
			padding-left: 27px;
			display: flex;
			align-items: center;
			@include font-base-14($n-17, true);

			&:before {
				top: 6px;
				left: 0;
				width: 10px;
				height: 10px;
				border: 2px solid $n-35;
			}

			&:after {
				top: 11px;
				left: 5px;
				width: 12px;
				height: 11px;
				background-color: $n-35;
			}
		}
	}
}
