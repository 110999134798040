.time-execution-table {
	padding: 24px;
	width: 49%;
	height: fit-content;
	border: 1px solid $n-90;
	border-radius: 4px;
	background-color: $white;
	flex-grow: 0;
	flex-shrink: 1;

	&:first-of-type {
		margin-right: 25px;
	}

	&__title {
		margin-bottom: 17px;
		display: flex;
		align-items: center;
	}

	&__name {
		margin-right: 7px;
		@include font-base-22($n-17, true);
	}

	&__header {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__wrapper {
		border-top: 1px solid $n-90;
		border-bottom: 1px solid $n-90;
	}

	&__item-row {
		border-top: 1px solid $n-90;
		display: flex;
		flex-direction: row;

		&:first-of-type {
			border: none;
		}
	}

	&__cell {
		padding: 8px 17px;
		display: flex;
		align-items: center;

		&--header {
			width: 50%;
			background-color: $n-95;
			text-transform: uppercase;
			@include font-base-14($n-35, true);
		}

		&--right {
			justify-content: right;
		}

		&--description {
			max-width: 340px;
			@include font-base-16($n-17);
			word-break: break-all;
		}

		&--value {
			width: calc(100% - 120px);
			flex: 1;
			justify-content: flex-end;
			@include font-base-16($n-17);
			word-break: break-all;
			border-right: none;
		}
	}

	.help-icon-body {
		fill: #8d8da5;
	}

	@include app-grid-medium {
		width: 100%;
	}

	@include app-grid-small {
		width: 100%;
	}
}
