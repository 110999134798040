.assert-card {
	margin-top: 16px;
	width: 100%;
	border-radius: 8px;
	box-shadow:
		inset 0 -1px 0 0 $n-80,
		inset 0 1px 0 0 $n-90,
		inset 1px 0 0 0 $n-90,
		inset -1px 0 0 0 $n-90;

	&:first-child {
		margin-top: 0;
	}

	&__error {
		padding: 5px 17px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $r-55;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		@include font-base-12($r-28, true);
		background-color: $r-95;
	}

	&__critical-icon {
		margin-right: 8px;

		path {
			fill: $r-28;
		}
	}

	.button-group {
		display: none;
	}

	&:hover {
		border-color: $n-60;

		.button-group {
			margin-left: 16px;
			min-width: 124px;
			display: flex;
		}
	}

	.dropdown input {
		padding-right: 34px;
	}

	&--edit {
		height: unset;
		box-shadow:
			0 0 1px 0 rgba(45, 45, 57, 0.5),
			0 8px 12px -4px rgba(45, 45, 57, 0.3);
	}

	&--error {
		border: 1px solid $r-55;
		box-shadow: none;

		&:hover {
			border-color: $r-55;
		}
	}

	&--viewer {
		&:hover {
			.button-group {
				margin-left: 0;
				display: none;
			}
		}
	}

	// Rewrites
	.form-field__error-field,
	.dropdown__error,
	.section-dropdown__error,
	.form-field__label-wrapper {
		display: none;
	}

	.dropdown__list-item-content {
		word-break: keep-all;
	}
}
