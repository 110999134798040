.recent-runs {
	.date-picker__calendar-container {
		z-index: 999999;
	}

	.extended-pagination {
		margin-top: 32px;
		padding-bottom: 40px;
	}
}

@import "./subHeader/SubHeader";
