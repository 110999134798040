.line-chart {
	position: relative;

	&__loader {
		position: absolute;
		top: 0px;
		z-index: 9;
		background: linear-gradient(0.25turn, transparent, white, transparent);
		width: 40%;
		height: 32px;
		animation: skeletonAnimation 1.5s;
		will-change: transform;
		animation-iteration-count: infinite;
	}

	&__gradient {
		transform: scale(1.011) translate(-1px);
	}
}

@keyframes skeletonAnimation {
	0% {
		transform: translateX(-40px);
	}
	100% {
		transform: translateX(150px);
	}
}
