.token-info-form {
	margin: auto 0;
	padding: 16px 0 10px 0;
	display: flex;
	flex-grow: 1;
	justify-content: space-between;

	&__button-group {
		width: 260px;
		display: flex;
		justify-content: space-between;
	}

	&__button {
		margin-right: 14px;
		padding: 0 6px;

		svg path:nth-child(1) {
			fill: $p-48;
		}
	}

	&__icon {
		margin-right: 4px;
	}

	&__section--dates {
		margin-right: 10px;
		width: 170px;
		display: flex;
		flex-grow: 1;
		justify-content: center;
	}

	&__token {
		width: 400px;

		&--secured {
			padding-top: 6px;
			width: 400px;
			height: 20px;
			font-size: 26px;
			letter-spacing: -1px;
			color: $n-35;
			overflow: hidden;
		}
	}

	&__token-date-types {
		margin-right: 4px;
		@include font-base-14($n-17, true);
	}

	&__top-row {
		margin-bottom: 8px;
	}
}
