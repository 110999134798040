.payment-plan {
	padding: 16px;
	margin-top: 24px;
	display: flex;
	justify-content: space-between;
	border: 1px solid $n-80;
	border-radius: 8px;

	&--vertical {
		margin-right: 32px;
		padding: 0;
		width: 218px;
		flex-direction: column;
		justify-content: normal;
		transition: 0.3s;

		.payment-plan__text--strong {
			font-weight: 700;
		}

		.payment-plan__text--normal {
			font-weight: 400;
		}

		.payment-plan__details li {
			margin-bottom: 8px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		@include app-grid-small {
			width: 368px;
			transition: 0.3s;
		}

		@include app-grid-medium {
			width: 368px;
			transition: 0.3s;
		}
	}

	&:first-of-type {
		margin-bottom: 0;
	}

	&__type {
		margin-bottom: 3px;
		@include font-base-14($n-35, true);
	}

	&__name {
		@include font-base-16($n-17, true);
	}

	&__details li {
		margin-bottom: 3px;
		@include font-base-14($n-17);

		&:last-of-type {
			margin-bottom: none;
		}
	}

	&__text--strong {
		font-weight: 700;
	}

	&__controls {
		margin-top: 4px;
		text-transform: capitalize;
	}

	&__left {
		min-width: 100px;

		&--vertical {
			padding: 16px;
			height: 68px;

			.payment-plan__type {
				@include font-base-12($n-35, true);
			}

			.payment-plan__name {
				@include font-base-14($n-17, true);
			}

			.payment-plan__controls {
				display: flex;
				align-items: center;

				.form-radio.new-design {
					margin-top: 0;

					&:first-of-type {
						margin-right: 20px;
					}
				}

				.radio-group {
					flex-direction: row;
					column-gap: 16px;
				}
			}

			&:last-of-type {
				display: flex;
				align-items: center;
			}
		}
	}

	&__center {
		&--vertical {
			padding: 16px;
			height: 224px;
		}

		@include app-grid-small {
			height: auto;
		}

		@include app-grid-medium {
			height: auto;
		}
	}

	&__right {
		width: 132px;
		display: flex;
		justify-content: center;
		align-items: center;

		&--vertical {
			padding: 16px;
			width: 100%;

			.button {
				width: 100%;
				height: 100%;
				font-size: 14px;
			}
		}

		.button {
			white-space: nowrap;
		}
	}

	&__tooltip {
		border-bottom: 2px dotted $n-35;
	}
}
