.details {
	width: 268px;
	min-width: 268px;

	p {
		margin: 0;
		@include font-base-14($n-17);
		text-align: right;
	}

	&__row {
		padding: 12px 0;
		display: flex;
		justify-content: space-between;
		text-align: center;
		align-items: center;
	}

	p.details__row-name {
		color: $n-35;
		text-align: left;
	}

	&__table {
		height: 280px;
		padding: 8px 12px;
		border: 1px solid $n-90;
		border-radius: 8px;
	}

	&__title {
		padding-bottom: 16px;
		@include font-base-20($n-17, true);
	}
}
