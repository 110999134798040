.billing-and-invoice {
	&__loader {
		position: absolute;
		top: 250px;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 28px;
		z-index: 9;
	}

	&__content {
		margin-bottom: 31px;
		display: flex;
	}

	&__details {
		word-break: break-all;
	}

	&__details-block {
		margin-top: 20px;

		&:first-of-type {
			margin-top: 0;
		}
	}

	&__details-block-name {
		@include font-base-12($n-35, true);
		text-transform: uppercase;
	}

	&__details-block-value {
		@include font-base-14($n-17);

		&--top {
			margin-top: 4px;
		}
	}

	&__details-block-edit {
		padding: 2px 8px 2px 0;
		margin-top: 8px;
		border: none;
		@include font-base-14($p-48, true);
		cursor: pointer;

		svg {
			margin: 4px !important;

			path {
				fill: $p-48;
			}
		}
	}

	&__billing,
	&__payment {
		width: calc(50% - 16px);
	}

	&__payment {
		margin-left: 24px;

		.divider--horizontal {
			margin-bottom: 20px;
		}
	}

	&__heading {
		margin-bottom: 24px;
		display: flex;
		justify-content: space-between;

		.button {
			padding: 6px 12px 6px 10px;
			white-space: nowrap;
		}
	}

	&__title {
		@include font-base-20($n-17, true);
	}

	&__invoice-title {
		@include font-base-20($n-17, true);
		margin-bottom: 18px;
	}

	hr {
		margin-bottom: 32px;
		border: 1px solid $n-90;
	}

	&__invoices {
		table {
			width: 100%;
			border-collapse: collapse;

			th {
				padding: 16px;
				@include font-base-12($n-35, true);
				text-transform: uppercase;
				letter-spacing: 0;
				background: $y-98;
				text-align: left;
			}

			td {
				padding: 16px;
				@include font-base-14($n-17);
			}
		}

		.divider--light {
			margin-bottom: 32px;
		}
	}

	&__invoices-table-wrapper {
		border: 1px solid $n-90;
		border-radius: 8px;
		overflow: hidden;
	}

	&__body-row {
		border-top: 1px solid $n-90;

		&--unpaid {
			background-color: $y-95;
		}
	}

	&__cell {
		&--small {
			width: 15%;
		}

		&--large {
			width: 40%;
		}
	}

	&__link {
		width: 110px;
		text-decoration: none;
		white-space: nowrap;

		&--disabled {
			color: $n-60;
			background: $white;
			border: none;
			pointer-events: none;

			&:hover {
				cursor: not-allowed;
				color: $n-60;
				background: $white;
			}
		}

		.running-loader {
			margin-left: 6px;
		}
	}

	&__status {
		display: flex;
		align-items: center;
		@include font-base-14($r-39, true);

		svg {
			margin-right: 8px;
		}
	}

	.billing-and-invoice__modal {
		width: 568px;

		// Overwrites
		.modal-buttons {
			display: flex;
			justify-content: flex-end;

			&--space-between {
				justify-content: space-between;
			}
		}

		.button:first-of-type {
			margin-right: 8px;
		}
	}
}

@import "forms/paymentCardForm/PaymentCardForm";
@import "forms/emailForm/EmailForm";
@import "forms/companyForm/CompanyForm";
@import "forms/addressForm/AddressForm";
