.account-settings-profile {
	position: relative;
	margin-bottom: 32px;
	width: 100%;
	display: flex;

	&__account-id {
		margin-bottom: 16px;
		display: block;

		&--title {
			margin-bottom: 6px;
			@include font-base-14($n-17, true);
		}

		.copy-id {
			@include font-base-14($n-17);
		}
	}

	&__avatar {
		margin-right: 40px;
		width: 145px;
	}

	&__info {
		position: relative;
		flex: 1;
	}

	&__save-btn {
		float: right;
	}

	&__cookies {
		margin: 32px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-title {
			@include font-base-14($n-17, true);
		}
	}
}
