.create-mos-test-screen {
	width: 100%;
	height: 100%;
	padding-bottom: 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: $n-95;

	&__loading {
		margin: auto 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 36px;
	}

	&__loading-text {
		@include font-base-14($n-35);
	}

	&__logo {
		margin-bottom: 24px;
		height: 24px;
	}
}
