.participant-modal {
	&__section {
		margin-bottom: 16px;
		width: 100%;
		display: flex;
	}

	&__title {
		margin-right: 16px;
		width: 360px;
	}

	&__count {
		flex: 1;

		.form-field {
			user-select: none;
			-webkit-user-select: none;
			-ms-user-select: none;
		}
	}

	&__cancel-btn {
		margin-right: 8px;
	}

	&__label {
		.badge {
			margin-left: 8px;
			padding: 2px 4px;
		}
	}

	&__tooltip-wrapper {
		position: relative;

		&--count {
			.tooltip--sm {
				width: 300px;
			}

			.form-field--disabled {
				.form-field__add:before {
					border-bottom: 4px solid $n-60;
				}
				.form-field__substract:before {
					border-top: 4px solid $n-60;
				}
			}
		}

		.tooltip--sm {
			position: absolute !important;
			top: 5px !important;
			left: 50% !important;
			transform: translate(-50%, -50%) !important;
		}
	}

	.form-field__label {
		user-select: none;
	}

	.dropdown {
		margin-bottom: 20px;

		&__label {
			user-select: none;
		}
	}

	.dropdown--disabled .dropdown__input input {
		color: $n-60;
	}
}
