.precondition {
	position: relative;
	padding: 0 12px 12px 8px;
	display: flex;
	align-items: center;

	.button--icon {
		padding: 8px;
		margin-left: 12px;
		min-width: unset;

		svg {
			margin: 0;
		}
	}

	.dropdown {
		width: 164px;
	}

	.dropdown--margin-right {
		margin-right: 12px;
	}

	.dropdown--custom-width,
	.form-field {
		min-width: 241px;
	}

	&__prefix {
		margin-right: 19px;
		@include font-base-14($n-17);

		&--is {
			margin-right: 32px;
		}
	}
}
