.tests-sub-header {
	margin: 16px 0;
	padding-bottom: 16px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	&__button {
		padding-left: 8px !important;
		width: 111px;
		white-space: nowrap;

		&:disabled svg path {
			fill: $n-60;
		}
	}

	&__filter-btn {
		position: relative;
		padding-left: 10px !important;
		justify-content: left;

		&--active {
			background-color: $p-90 !important;
			color: $p-37 !important;

			svg path {
				fill: $p-37;
			}
		}

		&--wide {
			width: 120px;
		}

		&--ultrawide {
			width: 100%;
		}
	}

	.tooltip {
		padding: 4px 8px !important;
		font-size: 12px !important;
		line-height: 16px !important;
		z-index: 9999 !important;

		&:before {
			z-index: -1 !important;
		}
	}

	&__right {
		display: flex;
		justify-content: space-between;
		column-gap: 8px;

		.dropdown {
			width: 196px;

			&__list .dropdown__list-element:focus {
				box-shadow: none !important;
			}

			input {
				padding-right: 36px;
			}
		}
	}

	&__search {
		position: relative;

		.form-field__label-wrapper {
			margin-bottom: 0;
		}

		input {
			padding: 7px 32px 7px 36px;
			width: 320px;
			border: 1px solid $n-75;
		}
	}

	&__close-icon {
		position: absolute;
		top: 10px;
		right: 12px;
		cursor: pointer;

		&:hover path {
			fill: $n-35;
		}
	}

	&__search-icon {
		position: absolute;
		top: 8px;
		left: 12px;
		cursor: pointer;
	}
}
