.test-builder-stepper {
	padding-top: 16px;
	margin: 0 auto;
	width: calc(100% - 32px);
	transition: all 0.7s ease;

	.app-container {
		width: 100%;
	}
}
