.assert-card-modify {
	&__top {
		padding: 16px 12px;
	}

	&__metric-path-list {
		margin-bottom: 8px;
		display: flex;
		align-items: center;
	}

	&__metric-path-elem {
		margin-left: 8px;

		&:first-child {
			margin-left: 0;
		}

		&--text {
			@include font-base-14($n-17);
		}

		&--dd {
			flex: 1;
		}

		.toggle {
			width: 104px;
			justify-content: center;
		}

		&--input {
			position: relative;
			flex: 1;
			min-width: 160px;

			input {
				padding-right: 65px;
			}
		}
	}

	&__divider {
		height: 100%;
		width: 100%;
		border-top: 1px solid $n-90;
	}

	&__bottom {
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__button-group {
		display: flex;
	}

	&__save-btn {
		margin-right: 8px;
	}

	&__tooltip-btn-wrapper {
		display: inline-block;
	}

	&__unit {
		position: absolute;
		top: 6px;
		right: 11px;
		@include font-base-14($n-17);
	}

	&__measurement,
	&__statistic {
		min-width: 150px;
	}
}
