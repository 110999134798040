.filters {
	margin-bottom: 24px;
	border-radius: 4px;
	display: flex;
	float: left;
	overflow: hidden;

	&__button {
		padding: 8px 10px;
		border-left: 1px solid $white;
		background-color: $n-95;
		@include font-base-14($n-35, true);
		text-transform: capitalize;
		cursor: pointer;
		transition: all 0.2s ease-out;

		&:first-of-type {
			border-left: none;
		}

		&:hover {
			background-color: $n-90;
		}

		&--active {
			background-color: $p-48;
			color: $white;

			&:hover {
				background-color: $p-48;
			}
		}
	}
}
