.report-participants {
	position: relative;
	width: 100%;
	display: block;

	&__table {
		position: relative;
		margin-bottom: 32px;
		display: block;
	}

	&__table-body {
		position: relative;
	}

	&__table-row {
		position: relative;
		padding: 0 16px;
		min-height: 49px;
		border-bottom: 1px solid $n-95;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:hover {
			background: $y-98;
		}

		.report-participants__table-cell {
			padding-right: 16px;

			&--status {
				position: relative;
				padding-left: 28px;

				svg {
					position: absolute;
					top: 17px;
					left: 4px;
					width: 18px;
					height: 18px;
					display: block;
				}
			}

			&--timeout svg {
				top: 15px;
			}
		}

		&--disabled {
			cursor: not-allowed;
			background: $y-98;

			div {
				pointer-events: none;
			}

			a,
			.report-participants__table-cell {
				color: $n-60;
			}

			&:hover {
				background: $y-98;
			}
		}
	}

	&__table-head {
		position: relative;
		padding: 0 16px;
		height: 36px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: $n-95;
	}

	&__table-head-cell {
		padding: 18px 7px 16px;
		display: flex;
		align-items: center;
		height: 100%;
		@include font-base-12($n-35, true);
		text-transform: uppercase;
		text-align: left;

		&--sortable {
			cursor: pointer;
			user-select: none;
		}

		&--disabled {
			cursor: unset;
		}

		&--id {
			width: 5%;
		}

		&--participant-title {
			width: 13%;

			a {
				@include font-base-14($p-37, true);

				&:hover {
					color: $n-17;
					text-decoration: none;
				}
			}
		}

		&--compute-units {
			width: 5%;
			text-transform: uppercase;
		}

		&--browser {
			width: 12%;
		}

		&--location {
			width: 14%;
		}

		&--network {
			width: 13%;
		}

		&--status {
			width: 10%;
		}

		&--audio-feed {
			width: 13%;
		}

		&--video-feed {
			width: 15%;
		}
	}

	&__table-cell {
		position: relative;
		padding: 16px 7px 16px;
		overflow: hidden;
		@include font-base-14($n-17);
		text-align: left;
		word-wrap: break-word;

		&--id {
			width: 5%;
		}

		&--participant-title {
			width: 13%;

			a {
				@include font-base-14($p-37, true);

				&:hover {
					color: $n-17;
					text-decoration: none;
				}
			}
		}

		&--compute-units {
			width: 5%;
			text-transform: uppercase;
		}

		&--browser {
			width: 12%;
		}

		&--location {
			width: 14%;
		}

		&--network {
			width: 13%;
		}

		&--status {
			width: 10%;
			text-transform: capitalize;
		}

		&--audio-feed {
			width: 13%;
		}

		&--video-feed {
			width: 15%;
		}
	}

	&__filter-container {
		width: 100%;
		display: flex;
		align-items: center;
		column-gap: 16px;
		flex-wrap: wrap;

		.dropdown-filter {
			margin: 0 0 24px 0;
			width: 153px;
		}
	}

	&__sort-icon {
		margin-right: 4px;
	}

	&__pagination-wrapper {
		display: flex;
		justify-content: center;
	}
}
