.token-info-modal {
	.modal-box {
		width: 860px !important;
	}

	&__content {
		padding: 0 16px 16px 16px;
	}

	&__footer {
		padding: 16px;
		display: flex;
		justify-content: space-between;
	}

	&__scope {
		margin-bottom: 6px;
		width: 33%;
	}

	&__scopes {
		display: flex;
		flex-wrap: wrap;
	}

	&__title {
		@include font-base-20($n-17, true);

		&--modal {
			padding: 16px;
		}

		&--scopes {
			margin: 16px 0;
		}
	}
}
