.test-builder-edit {
	width: 100%;
	height: 100%;

	&__divider {
		height: 1px;
		width: 100%;
		border-bottom: 1px solid $n-90;
	}

	&__info {
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		@include font-base-14($n-17);

		&--column {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__info-url {
		margin-left: 13px;
		display: inline-flex;
		align-items: center;
		@include font-base-14($p-48, true);

		&:hover {
			text-decoration: underline;
			color: $n-17;

			svg path {
				fill: $n-17;
			}
		}

		&--no-margin {
			margin-left: 0;
		}
	}

	&__info-icon {
		margin-right: 5px;
	}

	&__banners {
		margin: 0 auto;
		padding: 0 16px;
		max-width: 1200px;
	}

	a {
		text-decoration: none;
	}

	.button-group button {
		&:disabled {
			color: $n-60;
			background: $n-90;

			path {
				fill: $n-60;
			}
		}

		path {
			fill: $n-17;
		}
	}

	.loader {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@import "../common/steps/script/ScriptLayout";
	@import "./modals/exitModal/ExitModal";
	@import "./modals/groupModal/GroupModal";
	@import "./modals/participantModal/ParticipantModal";
	@import "./steps/details/Details";
	@import "./steps/participants/Participants";
}
