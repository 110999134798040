.chart-settings {
	position: sticky;
	top: 108px;
	width: 100%;
	max-height: 72px;
	z-index: 99;

	&--disabled-sticky {
		position: static;
	}

	&__content-hider {
		position: absolute;
		top: 0;
		width: 100%;
		min-height: 36px;
		z-index: -1;
		background: white;
	}

	&__wrapper {
		padding: 8px 16px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: $y-98;
		border: 1px solid $n-90;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		border-bottom: none;
		cursor: pointer;

		&--sticky {
			border-bottom: 1px solid $n-90;
		}

		&--open {
			padding-bottom: 4px;

			.chart-settings__body {
				transition: all 0.25s ease-in;
				max-height: 200px;
				pointer-events: all;
				opacity: 1;
			}
		}

		&:hover {
			.chart-settings__toggle {
				display: block;
			}
		}
	}

	&__header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__title {
		position: relative;
		margin: 0 8px 0 6px;
		@include font-base-14($n-17, true);
	}

	&__toggle {
		position: absolute;
		top: 3px;
		right: -25px;
		display: none;
		transform: rotate(-180deg);

		&--visible {
			display: block;
			transform: rotate(0deg);
			transition: all 0.2s;
			will-change: transform;
		}
	}

	&__body {
		display: flex;
		align-items: center;
		width: 100%;
		max-height: 0;
		transition: all 0.15s ease-out;
		pointer-events: none;
		will-change: max-height;
		opacity: 0;
	}

	&__button-group {
		display: flex;
	}

	&__interval {
		margin: 11px 16px 0 0;
		@include font-base-14($n-17, true);
	}

	&__button {
		margin-top: 11px;
		padding: 4px 12px;
		background: $n-95;
		border-left: 1px solid $white;
		@include font-base-14($n-35, true);
		cursor: pointer;

		&:hover {
			background: $n-90;
		}

		&--first {
			border-left: 1px solid $n-95;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}

		&--last {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			border-right: 1px solid $n-95;
		}

		&--active {
			background: $p-48;
			color: $white;

			&:hover {
				background: $p-48;
			}
		}

		&--loader {
			width: 36px;
			height: 100%;
			position: relative;

			.loader--primary .loader__bounce {
				background-color: $white;
			}
		}
	}

	&--sticky {
		z-index: 99;
	}
}
