.payment-form {
	&__label,
	.label {
		margin-bottom: 8px;
		@include font-base-14($n-17, true);

		&::after {
			content: "*";
			color: $r-39;
		}
	}

	.StripeElement {
		padding: 7px 10px;
		width: 100%;
		height: 32px;
		border-radius: 4px;
		border: solid 1px $n-75;
		display: block;
		font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
			"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
		@include font-base-14($n-17);
		text-align: left;
		-webkit-appearance: none;

		&:hover {
			border: 1px solid $n-60;
		}

		&:focus {
			border: 1px solid $p-48;
			box-shadow: 0 0 0 2px rgba(108, 48, 207, 0.25);
		}

		&:-webkit-autofill:focus {
			box-shadow: 0 0 0 2px rgba(108, 48, 207, 0.25) !important;
		}
	}

	.StripeElement--focus {
		border: 1px solid $p-48 !important;
		box-shadow: 0 0 0 2px rgba(108, 48, 207, 0.25);
	}

	&__stripe-input-group {
		&--invalid .StripeElement {
			border: 1px solid $r-39;

			&:hover {
				border: 1px solid $r-39;
			}
		}

		&--invalid .StripeElement--focus {
			border: 1px solid $r-39 !important;
			box-shadow: unset !important;
		}
	}

	&__error-field {
		margin-top: 8px;
		width: 100%;
		display: flex;
		align-items: flex-start;
		column-gap: 4px;

		svg {
			width: 19px;
			flex: none;
		}
	}

	&__message {
		@include font-base-14($r-39, true);
	}

	&__row {
		margin: 16px 0 4px;
		display: flex;
	}

	&__col {
		width: 140px;
	}

	&__expiry-date {
		margin-right: 16px;

		input,
		.StripeElement {
			padding-left: 44px;
		}
	}

	&__card-number {
		position: relative;

		.card-icon {
			position: absolute;
			top: 32px;
			left: 4px;
		}

		input,
		.StripeElement {
			padding-left: 57px;
		}
	}

	&__cvc {
		position: relative;

		.payment-form__card-back-icon {
			position: absolute;
			top: 32px;
			right: 4px;
		}

		input,
		.StripeElement {
			padding-left: 12px;
		}
	}

	.modal-actions {
		display: flex;
		justify-content: flex-end;

		button:first-of-type {
			margin-right: 8px;
		}
	}

	&__back-btn {
		margin-right: 8px;
	}

	&__actions {
		margin-top: 28px;
	}

	.form-field {
		margin-bottom: 8px;
	}
}
