.download-wrapper {
	&__button {
		width: 32px;
		padding: 6px !important;

		svg {
			margin: 0 !important;
		}
	}

	&__link {
		color: $p-48;
		text-decoration: underline;

		&:hover {
			color: $n-17;
		}

		&--bold {
			font-weight: bold;
		}

		&--disabled {
			color: $n-60;
		}
	}
}
