.upgrade-banner {
	padding: 16px;
	width: 568px;
	background-color: $n-17;
	background-image: url("../../assets/images/upgradeBanner.png");
	background-position: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	border-radius: 8px;

	@include app-grid-medium {
		width: 448px;
		background-position: 240px;
	}

	@include app-grid-small {
		width: 448px;
		background-position: 240px;
	}

	&__title {
		margin-bottom: 10px;
		@include font-base-22($white, true);
	}

	&__desc {
		margin-bottom: 24px;
		@include font-base-14($white);
	}

	&__text {
		margin-top: 12px;
	}

	&__link {
		margin-top: 12px;
		@include font-base-14($p-80, true);
		text-decoration: none;
	}

	//Overwrites
	.button--upgrade-banner {
		height: 40px;
		background: $t-95;
		color: $t-17;
	}

	svg {
		margin-right: 8px;
	}
}
