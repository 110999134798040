.contact-form {
	width: 100%;

	&__title {
		margin-bottom: 6px;
		@include font-base-39($n-17, true);
		text-align: center;
	}

	&__title-feedback {
		@include font-base-20($n-17, true);
	}

	&__subtitle {
		padding-bottom: 5px;
		margin: 0 auto;
		max-width: 420px;
		@include font-base-16($n-17);
		text-align: center;
	}

	.cancel {
		margin-right: 8px;
	}

	.button-wrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	&__user-details {
		margin: 0 auto;
		width: 100%;
		display: flex;
		justify-content: space-between;

		.form-field {
			width: 48%;
		}
	}

	&__input-title--critical {
		color: $r-39;
	}

	&--feedback {
		.form-field__label {
			font-weight: 400;
		}
	}
}
