.trends {
	width: 100%;

	&__chart-title {
		@include font-base-14($n-17, true);
	}

	&__chart-top {
		padding-bottom: 32px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__chart-container {
		padding: 18px 24px 24px;
		height: 280px;
		border: 1px solid $n-90;
		border-radius: 8px;
	}

	&__chart-datepicker-wrapper {
		position: absolute;
		top: 27px;
		right: 206px;

		@media (max-width: 1044px) {
			top: 210px;
			right: -16px;
		}
	}

	&__dropdown-wrapper {
		position: relative;
		width: 218px;
	}

	&__title {
		padding-bottom: 16px;
		@include font-base-20($n-17, true);
	}

	@include app-grid-small {
		max-width: 638px;
	}
}
