.participant-results-summary {
	&__container {
		display: flex;

		@include app-grid-medium {
			display: block;

			.metric-table {
				width: 100%;
			}
		}

		@include app-grid-small {
			display: block;
		}
	}

	&__mos-message {
		margin-bottom: 20px;
		display: flex;
		align-items: center;

		svg {
			margin-right: 6px;
			flex-shrink: 0;
		}
	}

	// Overwrites
	.badge {
		margin-bottom: 13px;
	}
}
