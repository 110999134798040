.report {
	position: relative;
	padding-bottom: 80px;
	width: 100%;
	display: block;

	&__back-btn {
		position: sticky;
		top: 108px;
		z-index: 9;
		margin-top: -16px;
		margin-bottom: 16px;
		padding-top: 16px;
		display: flex;
		background-color: $white;
		overflow-wrap: anywhere;
		cursor: pointer;

		svg {
			margin-top: 4px;
			width: 14px;
			height: 12px;
		}

		span {
			padding-left: 8px;
			@include font-base-14($n-35, true);
		}
	}

	&__title {
		margin-bottom: 24px;
		overflow-wrap: anywhere;
		@include font-base-26($n-17, true);
	}

	&__general-info {
		position: relative;
		border-top: none;
		border-radius: 4px;
		display: flex;
	}

	&__content {
		margin-bottom: 94px;
	}

	&__tab-navigation-wrapper {
		margin-bottom: 16px;
	}

	.tab-navigation {
		margin-top: 40px;
		margin-bottom: -1px;

		&__tab:last-child {
			margin-left: auto;
			margin-right: 0;
		}

		&__tab-title {
			color: $n-35;
		}

		&__tab {
			&:hover {
				.tab-navigation__tab-title {
					color: $n-17;
				}
			}

			&--active {
				.tab-navigation__tab-title {
					color: $p-48;
				}
			}

			&--disabled {
				.tab-navigation__tab-title {
					color: $n-60;
				}

				&:hover {
					.tab-navigation__tab-title {
						color: $n-60;
					}
				}
			}
		}
	}

	@import "./asserts/Asserts";
	@import "./participants/Participants";
	@import "./summary/Summary";
	@import "./viewScript/ViewScript";
}
