.tour {
	width: 328px;
	border-radius: 8px;
	box-shadow: 0 0 1px 0 rgba(45, 45, 57, 0.5),
		0 8px 12px -4px rgba(45, 45, 57, 0.3);
	background: $n-17;

	&__top {
		padding: 18px 19px 0 16px;
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__image {
		margin-bottom: 16px;
		height: 156px;
		padding: 0 16px;

		img {
			width: 100%;
			border-radius: 8px;
		}
	}

	&__title {
		@include font-base-20($white, true);
	}

	&__close {
		cursor: pointer;

		svg path {
			fill: $white;
		}
	}

	&__info {
		padding: 0 16px;
		@include font-base-14($white);
	}

	&__description {
		margin-bottom: 16px;
		@include font-base-14($white);
	}

	&__link {
		@include font-base-14($p-80, true);
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&__divider {
		margin: 17px 0;
		width: 100%;
		border: 1px solid $n-35;
	}

	&__footer {
		padding: 0 16px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__steps {
		@include font-base-12($white);
	}

	&__control {
		cursor: pointer;
		@include font-base-14($white, true);
	}

	&__prevent-bg-click {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
}
