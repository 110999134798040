.options-toggle {
	position: relative;
	margin-left: 8px;

	&__trigger {
		width: 32px;
		height: 32px;
		border: 1px solid $n-75;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			background: $n-95;
		}
	}

	&__body {
		position: absolute;
		top: 41px;
		right: 0;
		z-index: 9;
		padding: 12px 16px 14px 16px;
		min-width: 236px;
		display: none;
		border-radius: 8px;
		box-shadow: 0 0 1px 0 rgba(45, 45, 57, 0.5),
			0 8px 12px -4px rgba(45, 45, 57, 0.3);
		background: $white;
	}

	&__name {
		margin-bottom: 18px;
		text-transform: uppercase;
		@include font-base-12($n-35, true);
	}

	&--active .options-toggle__body {
		display: block;
	}
}
