.payments-payment {
	margin: 0 auto;
	width: 768px;
	display: flex;

	&__left {
		width: 60%;
	}

	&__right {
		width: 40%;
	}

	&__heading {
		margin-bottom: 9px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__title {
		@include font-base-26($n-17, true);
	}

	&__secured {
		margin-bottom: 31px;
		display: flex;
		align-items: center;
		@include font-base-14($n-35, true);

		svg {
			margin-right: 4px;
			padding-bottom: 4px;
		}
	}

	&__action-buttons-row {
		margin-top: 32px;
		display: flex;
		align-items: center;
	}

	&__back-btn {
		margin-right: 16px;
	}

	&__card-modal {
		margin-bottom: 15px;
	}
}
