@mixin app-grid-small {
	@media (max-width: 960px) {
		@content;
	}
}

@mixin app-grid-medium {
	@media (min-width: 961px) and (max-width: 1200px) {
		@content;
	}
}

@mixin app-grid-wide {
	@media (min-width: 1200px) {
		@content;
	}
}

@mixin button-base(
	$bg: $wisteria,
	$hoverBG: $twilight,
	$border: none,
	$radius: 4px
) {
	border-radius: $radius;
	border: $border;
	background: $bg;
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		background: $hoverBG;
	}
}

@mixin full-size($pos: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0) {
	position: $pos;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin pseudo($content: "", $display: block, $pos: absolute) {
	content: $content;
	position: $pos;
	display: $display;
}

@mixin thumb-base() {
	width: 32px;
	height: 32px;
	border: 1px solid $white;
	background: url("../../landing/assets/media/slider.svg") no-repeat;
	background-position: center;
	background-size: 80%;
	background-color: $white;
	border-radius: 50%;
	box-shadow: 0 3px 8px 0 rgba(68, 67, 86, 0.2);
	cursor: pointer;
	-webkit-appearance: none;
}

@mixin thumb() {
	&::-webkit-slider-thumb {
		@include thumb-base();
	}

	&::-moz-range-thumb {
		@include thumb-base();
	}

	&::-ms-thumb {
		@include thumb-base();
	}
}

@mixin center() {
	position: relative;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

@mixin font-base($color, $bold) {
	color: $color;

	@if $bold == true {
		font-weight: 700;
	} @else {
		font-weight: 400;
	}
}

@mixin font-bases($size, $colour, $weight, $lh) {
	font-size: $size;
	font-weight: $weight;
	line-height: $lh;
	color: $colour;
}

@mixin font-base-10($color, $bold: false) {
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.4px;

	@include font-base($color, $bold);
}

@mixin font-base-12($color, $bold: false) {
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;

	@include font-base($color, $bold);
}

@mixin font-base-13($color, $bold: false) {
	font-size: 13px;
	line-height: 16px;

	@include font-base($color, $bold);
}

@mixin font-base-14($color, $bold: false) {
	font-size: 14px;
	line-height: 20px;

	@include font-base($color, $bold);
}

@mixin font-base-16($color, $bold: false) {
	font-size: 16px;
	line-height: 24px;

	@include font-base($color, $bold);
}

@mixin font-base-18($color, $bold: false) {
	font-size: 18px;
	line-height: 24px;

	@include font-base($color, $bold);
}

@mixin font-base-20($color, $bold: false) {
	font-size: 20px;
	line-height: 28px;

	@include font-base($color, $bold);
}

@mixin font-base-22($color, $bold: false) {
	font-size: 22px;
	line-height: 28px;

	@include font-base($color, $bold);
}

@mixin font-base-23($color, $bold: false) {
	font-size: 23px;
	line-height: 32px;

	@include font-base($color, $bold);
}

@mixin font-base-24($color, $bold: false) {
	font-size: 24px;
	line-height: 32px;

	@include font-base($color, $bold);
}

@mixin font-base-25($color, $bold: false) {
	font-size: 25px;
	line-height: 36px;

	@include font-base($color, $bold);
}

@mixin font-base-26($color, $bold: false) {
	font-size: 26px;
	line-height: 36px;

	@include font-base($color, $bold);
}

@mixin font-base-28($color, $bold: false) {
	font-size: 28px;
	line-height: 36px;

	@include font-base($color, $bold);
}

@mixin font-base-30($color, $bold: false) {
	font-size: 30px;
	line-height: 40px;

	@include font-base($color, $bold);
}

@mixin font-base-31($color, $bold: false) {
	font-size: 31px;
	line-height: 40px;

	@include font-base($color, $bold);
}

@mixin font-base-32($color, $bold: false) {
	font-size: 32px;
	line-height: 40px;

	@include font-base($color, $bold);
}

@mixin font-base-35($color, $bold: false) {
	font-size: 35px;
	line-height: 44px;

	@include font-base($color, $bold);
}

@mixin font-base-36($color, $bold: false) {
	font-size: 36px;
	line-height: 44px;

	@include font-base($color, $bold);
}

@mixin font-base-39($color, $bold: false) {
	font-size: 39px;
	line-height: 48px;

	@include font-base($color, $bold);
}

@mixin font-base-49($color, $bold: false) {
	font-size: 49px;
	line-height: 64px;

	@include font-base($color, $bold);
}

@mixin font-base-64($color, $bold: false) {
	font-size: 64px;
	line-height: 76px;

	@include font-base($color, $bold);
}
