.action-menu {
	position: absolute;
	z-index: 9;
	right: 0;
	top: 28px;
	padding: 8px;
	width: 208px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	overflow: hidden;
	border-radius: 8px;
	box-shadow:
		0 0 1px 0 rgba(45, 45, 57, 0.5),
		0 8px 12px -4px rgba(45, 45, 57, 0.3);
	background-color: $white;

	&__option {
		padding: 6px 8px;
		width: 100%;
		display: flex;
		column-gap: 8px;
		cursor: pointer;
		@include font-base-14($n-17);

		&:focus,
		&:active,
		&:hover {
			background-color: $n-95;
			border-radius: 4px;
		}
	}

	.divider {
		margin: 8px auto;
	}
}
