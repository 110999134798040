.payments-billing {
	margin: 0 auto;
	padding-bottom: 20px;
	width: 768px;
	display: flex;

	&__left {
		width: 468px;
	}

	&__right {
		width: 300px;
	}

	&__title {
		margin-bottom: 29px;
		@include font-base-26($n-17, true);
	}

	&__description {
		margin-bottom: 32px;
		padding-bottom: 15px;
		@include font-base-14($n-17);
		border-bottom: 1px solid $n-90;
	}
}
