@import "assets/styles/colors";
@import "assets/styles/mixins";

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	clear: both;

	&:focus {
		outline: transparent;
	}
}

html,
body,
#root,
.app {
	position: relative;
	margin: 0;
	padding: 0;
	width: 100%;
	min-width: 310px;
	height: 100%;
	min-height: 475px;
	display: block;
	font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

html {
	-webkit-text-size-adjust: 100%;
}

html:has(.test-builder),
body:has(.test-builder) {
	overscroll-behavior-x: none;
}

button,
input {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

// remove yellow background for autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px white inset !important;
}

h1 {
	@include font-base-36($n-17, true);
}

h2 {
	@include font-base-28($n-17, true);
}

h3 {
	@include font-base-18($n-17, true);
}

p {
	@include font-base-16($n-35);
	margin-top: 20px;

	&:first-of-type {
		margin-top: 0;
	}
}

ul {
	list-style-type: none;
}

// Custom keyboard focus when tabbing
html[data-tabbing="true"] *:focus,
html[data-tabbing="true"] .form-field .input:-webkit-autofill:focus,
html[data-tabbing="true"] .StripeElement--focus {
	box-shadow:
		0 0 0 1px white,
		0 0 0 3px $b-44 !important;
	transition: all 0.3s ease !important;
}

html[data-tabbing="true"] .form-field input:focus,
html[data-tabbing="true"] .timer input:focus,
html[data-tabbing="true"] .form-field .input:-webkit-autofill:focus,
html[data-tabbing="true"] .StripeElement--focus {
	border: 1px solid $n-75 !important;
}

.no-scroll {
	overflow: hidden !important;
}

.no-overscroll-behavior {
	overscroll-behavior-x: none;
	-ms-scroll-chaining: none;
	overflow-x: hidden;
}

.no-transition {
	* {
		transition: none !important;
	}
}

.app-root {
	position: relative;
	margin: auto;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	transition: all 0.3s ease;

	@include app-grid-small {
		width: 960px;
		overflow-x: scroll;
	}
}

.app-content {
	position: relative;
	width: 100%;
}

.app-header-wrapper {
	position: sticky;
	top: 0;
	margin: auto;
	width: 100%;
	height: 72px;
	background-color: $white;
	z-index: 9999;
}

.navigation-menu-wrapper {
	position: sticky;
	top: 72px;
	margin-bottom: 16px;
	z-index: 999;
	width: 100%;
	background-color: $white;
}

.page-content {
	position: relative;
	margin: 0 auto;
	padding: 0 16px 0 16px;
	width: 100%;
	max-width: 1200px;
	min-height: 100%;
	display: flex;
	flex-direction: column;

	.page-loader-wrapper {
		position: absolute;
		top: 250px;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 28px;
		z-index: 9;
	}
}

.container {
	position: relative;
	height: 100%;
	min-height: inherit;
	max-width: 1366px;
	padding-right: 95px;
	padding-left: 95px;
	margin: 0 auto;
}

.app-container {
	margin: 0 auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: content-box;
	max-width: 1168px;
	transition: all 0.3s ease;

	&--flex-row {
		flex-direction: row;
	}

	&--space-between {
		justify-content: space-between;
	}

	@include app-grid-small {
		width: 912px;
	}
}

.button.button--loading .loader {
	position: relative;
}

// Code editor reference pane
.split-view-view {
	font-size: 12px;
}

@import "templates/accountSettings/AccountSettings";
@import "templates/createProject/CreateProject";
@import "templates/favorites/Favorites";
@import "templates/pageNotFound/PageNotFound";
@import "templates/participantResults/ParticipantResults";
@import "templates/projectSettings/ProjectSettings";
@import "templates/projectUpgrade/ProjectUpgrade";
@import "templates/recentRuns/RecentRuns";
@import "templates/report/Report";
@import "templates/selectProject/SelectProject";
@import "templates/singleTest/SingleTest";
@import "templates/testBuilder/common/asserts/AssertsLayout";
@import "templates/testBuilder/create/CreateTestBuilder";
@import "templates/testBuilder/edit/EditTestBuilder";
@import "templates/tests/Tests";

@import "./assets/styles/calendar";
@import "./assets/styles/imageCrop";
@import "./assets/styles/toasts";
@import "./components/components";
