.participant-modal {
	&__section {
		margin-bottom: 16px;
		width: 100%;
		display: flex;
	}

	&__title {
		margin-right: 16px;
		width: 360px;
	}

	&__count {
		flex: 1;

		.form-field {
			user-select: none;
			-webkit-user-select: none;
			-ms-user-select: none;
		}
	}

	&__cancel-btn {
		margin-right: 8px;
	}

	&__label {
		.badge {
			margin-left: 8px;
			padding: 2px 4px;
		}
	}

	.dropdown {
		margin-bottom: 20px;

		&__label {
			user-select: none;
		}
	}

	.form-field__label {
		user-select: none;
	}
}
