.modal-wrapper {
	overflow: hidden;

	.modal-box {
		width: 768px !important;
	}
}

.create-token-modal {
	max-height: 616px;
	display: flex;
	flex-direction: column;

	&__block {
		&--advanced-scopes {
			margin: 32px 0 16px 0;
		}

		&--basic-scopes {
			margin: 28px 0 24px 0;
			height: 70px;
			display: flex;
			justify-content: space-around;
			flex-direction: column;
		}

		&--expiration {
			margin: 32px 0;
			display: flex;
		}
	}

	&__cancel-button {
		margin-right: 8px;
	}

	&__checkbox {
		width: 235px;

		&:nth-of-type(3n + 1) {
			width: 255px;
		}
	}

	&__columns {
		width: 736px;
		display: flex;
		flex-wrap: wrap;
	}

	&__content {
		padding: 0 16px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	&__date-picker {
		margin-left: 120px;
	}

	&__footer {
		padding: 16px;
		display: flex;
		justify-content: right;
	}

	&__title {
		&--main {
			padding: 16px;
			@include font-base-20($n-17, true);
		}

		&--secondary {
			margin-right: 16px;
			@include font-base-14($n-17, true);
		}

		&--tertiary {
			margin: 20px 0 5px 0;
			@include font-base-12($n-35, true);
		}
	}
}
