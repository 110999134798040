.label-modal {
	&__name,
	&__bg-color-wrapper {
		display: flex;
		align-items: center;
		@include font-base-14($n-17, true);
	}

	&__name {
		margin-bottom: 24px;
		column-gap: 143px;
	}

	&__bg-color-wrapper {
		column-gap: 61.5px;

		&--bg {
			align-items: flex-start;
		}
	}

	&__bg-color-palette-row {
		&:first-child {
			margin-bottom: 16px;
		}

		&:nth-child(2) {
			margin-bottom: 8px;
		}

		display: flex;
		column-gap: 16px;
	}

	&__bg-color {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 4px;
		border: 1px solid rgba(38, 38, 48, 0.2);
		cursor: pointer;
	}

	&__cancel-btn {
		margin-right: 8px;
	}

	input {
		width: 352px;
		margin-bottom: 12px;
	}

	svg path {
		fill: $n-17;
	}
}
