.recent-runs-sub-header {
	position: relative;
	z-index: 9;
	margin-top: 20px;
	margin-bottom: 16px;
	padding-bottom: 16px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	.date-picker {
		min-width: 268px;
	}

	.date-picker__calendar-container {
		left: 0;
		width: 476px;
	}

	&__right {
		display: flex;
		justify-content: space-between;
		column-gap: 8px;

		.dropdown {
			width: 196px;

			input {
				padding-right: 36px;
			}
		}
	}

	&__search {
		position: relative;

		.form-field__label-wrapper {
			margin-bottom: 0;
		}

		input {
			padding-top: 7px;
			padding-left: 36px;
			width: 320px;
			border: 1px solid $n-75;
		}
	}

	&__filters {
		.filter-modal {
			top: 36px;
			right: 0px;
		}
	}

	&__filter-btn {
		position: relative;
		padding-left: 10px !important;
		justify-content: left;

		&--active {
			background-color: $p-90 !important;
			color: $p-37 !important;

			svg path {
				fill: $p-37;
			}
		}

		&--wide {
			width: 120px;
		}

		&--ultrawide {
			width: 100%;
		}
	}

	&__close-icon {
		position: absolute;
		top: 10px;
		right: 12px;
		width: 12px;
		height: 12px;
		cursor: pointer;

		&:hover path {
			fill: $n-35;
		}
	}

	&__search-icon {
		position: absolute;
		top: 8px;
		left: 12px;
		width: 16px;
		height: 16px;
		cursor: pointer;
	}
}
