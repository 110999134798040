.load {
	margin-top: 24px;
	padding: 24px;
	border: 1px solid $n-90;
	border-radius: 4px;

	&__title {
		margin-bottom: 16px;
		@include font-base-22($n-17, true);
	}

	&__chart {
		padding-top: 40px;
	}
}
