.choose-plan {
	&__title {
		@include font-base-24($n-17, true);
	}

	&__cards {
		margin-top: 8px;
		margin-bottom: 32px;
		display: flex;

		@include app-grid-small {
			flex-direction: column;
		}

		@include app-grid-medium {
			flex-direction: column;
		}
	}

	&__cards-wrapper {
		display: flex;
	}

	&__trial-banner {
		margin-top: 32px;
		padding: 16px 36px 16px 16px;
		width: 100%;
		max-width: 968px;
		height: 124px;
		display: flex;
		flex-shrink: 0;
		border: 1px solid $n-90;
		border-radius: 8px;
		background-color: $y-98;
		background-image: url("../../../../assets/images/trialBannerShapes.png");
		background-size: 550px 550px;
		background-position: 130% -260px;
		transition: all 0.3s ease;

		ul {
			display: flex;
			flex-wrap: wrap;
		}

		.button {
			flex-shrink: 0;
			align-self: center;
		}
	}

	&__trial-banner-title {
		margin-bottom: 12px;
		@include font-base-20($n-17, true);
	}

	&__trial-banner-item {
		margin-right: 12px;
		display: flex;
		align-items: flex-start;
		flex-shrink: 0;
		@include font-base-14($n-17);

		svg {
			margin-right: 8px;
			flex-shrink: 0;
		}

		span {
			border-bottom: 1px dashed $n-35;
		}

		&:first-child {
			margin-top: 0;
			font-weight: 700;
		}
	}

	&__trial-banner-items--first-row {
		display: flex;
		margin-bottom: 12px;
	}
}
