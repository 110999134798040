.metric-table {
	margin-bottom: 24px;
	padding: 24px;
	width: 49%;
	height: fit-content;
	border: 1px solid $n-90;
	border-radius: 4px;
	background-color: $white;

	&:first-of-type {
		margin-right: 23px;
	}

	&__title {
		margin-bottom: 17px;
		display: flex;
		align-items: center;
	}

	&__name {
		margin-right: 7px;
		@include font-base-22($n-17, true);
	}

	&__header {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__switch {
		padding: 4px;
		display: flex;
		width: 124px;
		height: 32px;
		background: $n-90;
		border-radius: 4px;
		@include font-base-14($n-17, true);
	}

	&__switch-btn {
		width: 50%;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: $n-17;

		&:hover {
			background: #c7c5d3;
		}

		&--active {
			background: $white;
		}
	}

	&__wrapper {
		border-top: 1px solid $n-90;
		border-bottom: 1px solid $n-90;
	}

	&__item-row {
		border-top: 2px solid $n-90;
		display: flex;
		flex-direction: row;

		&:first-of-type {
			border: none;
		}
	}

	&__cell {
		padding: 8px 17px;
		display: flex;
		align-items: center;
		border-right: 1px solid $n-90; // default

		&--param {
			width: 40%;
			min-width: 40%;
			background-color: $n-95;
			text-transform: uppercase;
			@include font-base-14($n-35, true);
		}

		&--description {
			width: 135px;
			@include font-base-16($n-17);
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		&--value {
			width: calc(100% - 120px);
			flex: 1;
			justify-content: flex-end;
			@include font-base-16($n-17);
			word-break: break-all;
			border-right: none;
		}
	}

	&__multi-row {
		width: 60%;
		display: flex;
		flex-direction: column;
	}

	&__row {
		border-bottom: 1px solid $n-90;
		display: flex;
		flex-direction: row;
		flex: 1;

		&:last-of-type {
			border-bottom: none;
		}

		&--single-value {
			padding: 8px 17px;
			align-items: center;
			justify-content: flex-end;
			word-break: break-all;
		}
	}

	.help-icon-body {
		fill: #8d8da5;
	}

	@include app-grid-small {
		width: 100%;
	}
}
