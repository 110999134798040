.test-builder-edit-details {
	width: 100%;

	&__wrapper {
		display: flex;
		justify-content: space-between;
	}

	&__form {
		width: 768px;

		@include app-grid-small {
			width: 608px;
		}

		&--full-width {
			width: 100%;
		}
	}

	&__tour {
		padding: 32px 0 0 32px;
	}

	&__group {
		padding: 32px 0;
		display: flex;
		align-items: flex-start;

		&--banner-active {
			.timer {
				max-width: 282px;
			}

			@include app-grid-wide {
				.timer {
					max-width: 365px;
				}
			}
		}
	}

	&__input-field {
		width: 288px;

		.form-field__warning-icon {
			margin-right: 8px;
		}
	}

	&__input-label {
		width: 400px;
		@include font-base-14($n-17, true);

		@include app-grid-small {
			width: 288px;
			padding-right: 32px;
		}
	}

	&__input-label-desc {
		margin-top: 12px;
		max-width: 346px;
		@include font-base-12($n-35);

		&--margin {
			margin-top: 4px;
		}
	}

	&__mos-test {
		display: flex;
		align-items: center;

		.switch {
			margin-bottom: 20px;
		}
	}

	&__mos-title {
		margin-right: 13px;
		margin-left: 8px;
		@include font-base-14($n-17);
	}

	&__project-language {
		padding: 4px 8px;
		background: $n-95;
		border-radius: 4px;
		@include font-base-14($n-17);
	}

	&__radio-wrapper {
		display: flex;
		flex-direction: column;
		row-gap: 4px;
	}

	&__radio-inner-wrapper {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		margin-bottom: 6px;
	}

	&__upgrade-btn {
		margin-left: 16px;
		transform: translate(0, 3px);
	}

	&__delete {
		color: $r-39;
	}

	&__button-delete {
		margin-top: 32px;
	}

	&__arrow-delete {
		display: flex;
		justify-content: flex-end;
		flex-grow: 1;

		svg {
			width: 20px;
			height: 20px;
			transform: rotate(-180deg);
			transition: transform 0.3s linear;
			cursor: pointer;
		}

		&--toggle svg {
			transform: rotate(0deg);
			transition: transform 0.3s linear;
		}
	}

	// Rewrites
	.form-field {
		margin-bottom: 0;
		flex: 1;
	}

	.form-field__label-wrapper,
	.form-field__error-field {
		margin: 0;
	}

	.button {
		display: flex;
	}

	.timer__error,
	.form-field__error-field {
		margin-top: 8px;
	}

	.form-field__warning-icon {
		margin-right: 8px;
	}
}
