.confirm-modal {
	@include full-size(fixed, 0, 0, 0, 0);
	width: 100%;
	height: 100%;
	background-color: rgba(106, 109, 138, 0.3);
	overflow-y: scroll;
	z-index: 9999999;

	&__modal-box {
		position: relative;
		left: 0;
		margin: 16px auto 60px;
		width: 568px;
		border-radius: 12px;
		box-shadow: 0 4px 11px -6px $n-35;
		background: $white;
	}

	&__message {
		@include font-base-14($n-17);
		word-break: break-word;
	}

	&__action-block {
		display: flex;
		justify-content: flex-end;

		button {
			margin-left: 8px;
		}
	}
}
