.subheader {
	&__actions {
		height: 32px;
		display: flex;
		column-gap: 8px;
	}

	&__action-divider {
		padding-left: 8px;
		padding-right: 8px;
	}

	&__action-menu-trigger {
		position: relative;

		.assign-label-modal,
		.convert-to-mos,
		.copy-test-modal,
		.action-menu {
			top: 36px;
		}
	}

	&__body {
		padding-top: 8px;
		display: flex;
		justify-content: space-between;
	}

	&__bottom-divider {
		padding-top: 24px;
	}

	&__favorite {
		padding-top: 5px;

		&:hover {
			cursor: pointer;
		}
	}

	&__icon-btn {
		padding: 6px !important;

		svg {
			margin-right: 0 !important;
		}
	}

	&__labels {
		padding-top: 12px;
		display: flex;
		flex-wrap: wrap;
		column-gap: 8px;
		row-gap: 8px;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__name-section {
		padding-right: 32px;
		display: flex;
		align-items: center;
		overflow-x: hidden;
	}

	&__name {
		@include font-base-24($n-17, true);
		overflow-wrap: anywhere;
		padding-right: 8px;
	}

	&__edit-test-btn,
	&__run-test-btn,
	&__view-test-btn {
		padding-left: 8px !important;
		width: 108px;
		white-space: nowrap;
	}

	&__view-test-btn svg {
		transform: scale(1.1);
	}

	&__run-test-btn svg {
		transform: scale(1.2);
	}

	&__run-test-btn svg,
	&__edit-test-btn svg {
		margin-right: 8px !important;
	}

	&__run-test-btn path {
		fill: $white;
	}

	&__run-test-btn:disabled path {
		fill: $n-60;
	}

	.react-tooltip {
		z-index: 99999;
	}
}
