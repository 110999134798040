.participant-group-layout {
	position: relative;
	margin-bottom: 16px;
	border: 1px solid $n-80;
	border-radius: 8px;
	background: $p-98;

	&__title {
		display: flex;
		align-items: center;
		width: 64.6%;

		@include app-grid-small {
			width: 54.8%;
		}

		@include app-grid-medium {
			width: 55.8%;
		}

		.badge--sm {
			flex-shrink: 0;
		}
	}

	&__name {
		margin-right: 12px;
		max-width: 100%;
		@include font-base-14($n-17, true);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:before {
		position: absolute;
		content: "";
		top: 50%;
		left: -2px;
		height: calc(100% - 16px);
		display: block;
		border-left: 3px solid $p-48;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		transform: scale(-1, 1) translate(-50%, -50%);
	}

	&__header {
		padding: 13px 16px 13px 18px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		.button-group {
			position: absolute;
			right: 172px;
			margin-right: 24px;
			opacity: 0;
		}
	}

	&__toggle {
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	&__icon {
		margin-right: 4px;
	}

	&__count {
		@include font-base-12($n-35);
	}

	&__card-btn {
		background-color: transparent;
		padding: 0;

		&:hover {
			background-color: transparent;
		}
	}

	&__add {
		margin-right: 16px;
		margin-left: 16px;
		padding: 2px 8px 2px 4px;
		display: flex;
		border-radius: 4px;
		@include font-base-14($p-48, true);
		align-items: center;
		cursor: pointer;
		transition: all 0.3s ease;

		&:hover {
			background: $p-90;
			color: $p-37;
		}
	}

	&__add-icon {
		margin: 0 6px 0 4px;

		path {
			fill: $p-48;
		}
	}

	&__toggle-icon {
		cursor: pointer;
	}

	&__toggle-btn,
	&__toggle-btn:hover {
		background: transparent;
	}

	&__body {
		padding: 0 12px 10px 14px;
		display: none;

		.button-group {
			opacity: 0;
			position: absolute;
			top: 16px;
			right: 16px;
		}
	}

	&--active {
		padding-bottom: 0;
		cursor: unset;

		&:before {
			height: calc(100% - 17px);
		}

		.participant-group-layout__body {
			display: block;
		}

		.participant-group-layout__header {
			padding-bottom: 16px;
		}

		.participant-group-layout__toggle-icon {
			transform: rotate(-180deg);
		}
	}

	&:hover,
	&:focus,
	&:active,
	&:has(.button:focus),
	&:has(.button:active) {
		border-color: $n-60;

		.participant-group-layout__count {
			margin-right: 144px;
		}

		.participant-group-layout__header .button-group {
			opacity: 1;
		}
	}

	&--viewer {
		&:hover {
			.participant-group-layout__count {
				margin-right: 0;
			}

			.participant-group-layout__header .button-group {
				display: none;
			}
		}
	}
}
