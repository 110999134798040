.log-file-card {
	padding: 8px;
	width: 100%;
	height: 49px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1px solid $n-90;
	border-radius: 4px 4px 0 0;

	&:hover {
		background-color: $n-95;
		cursor: pointer;
	}

	&:has(.log-file-card__preview:hover) {
		background-color: $white;
		cursor: default;
	}

	&--open {
		height: auto;
	}

	&--expired {
		&:hover {
			background-color: $white;
			cursor: default;
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__buttons {
		padding-right: 4px;
		display: flex;
		align-items: center;
		column-gap: 16px;
	}

	&__preview {
		margin-top: 16px;
		height: 497px;
		overflow: hidden;

		&--loading {
			height: 112px;
		}

		&--too-big-file {
			padding: 32px;
			display: flex;
			justify-content: center;
			column-gap: 4px;
			@include font-base-14($n-35, true);
			text-align: center;
		}

		// Overwrites
		.progress-bar {
			padding-top: 25px;
		}
	}

	&__preview-log-line {
		padding-right: 32px;
	}

	&__preview-toggle {
		height: 17px;
		transform: rotate(180deg);
		transition: transform 0.2s;

		&:hover {
			cursor: pointer;
		}

		&--active {
			transform: rotate(0deg);
		}
	}

	&__name {
		padding: 4px;
		@include font-base-14($n-17, true);
	}

	.monaco-hover {
		display: none;
	}
}

.log-file-card ~ .log-file-card {
	border-radius: 0;
	border-top: none;
}
.log-file-card:last-of-type {
	border-radius: 0 0 4px 4px;
}
