$white: #ffffff;
$black: #000000;

$b-44: #1c8cc4;
$g-55: #36e29d;
$n-17: #262630;
$n-17-2: rgba(68, 67, 86, 0.2);
$n-17-3: rgba(68, 67, 86, 0.3);
$n-17-8: rgba(68, 67, 86, 0.8);
$n-30: #444356;
$n-35: #4f4f64;
$n-60: #8d8da5;
$n-75: #b8b8c7;
$n-80: #c6c6d2;
$n-85: #c9c9cc;
$n-90: #e3e3e8;
$n-92: #e3e2e9;
$n-95: #f1f1f4;
$p-17: #251046;
$p-37: #502499;
$p-48: #682ec7;
$p-60: #8959d9;
$p-80: #c4acec;
$p-90: #e2d6f5;
$p-98: #f9f7fd;
$r-28: #7d1221;
$r-55: #e2364f;
$r-39: #ae192e;
$r-95: #fce9eb;
$t-17: #064a51;
$t-55: #29deee;
$t-80: #a1eff7;
$t-95: #e7fbfd;
$y-14: #443404;
$y-36: #ae8709;
$y-55: #f3c425;
$y-95: #fef8e7;
$y-98: #f9f9fb;
