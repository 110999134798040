.test-builder-header {
	position: relative;
	width: 100%;
	height: 108px;
	padding-top: 16px;
	background-color: $n-95;
	transition: all 0.7s ease;

	@include app-grid-small {
		max-width: 960px;
		padding: 16px 0px 0px;
	}

	&__top,
	&__bottom {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__top {
		margin-bottom: 20px;
	}

	&__bottom {
		height: 40px;
	}

	&__left-actions,
	&__right-actions {
		display: flex;
		align-items: center;

		svg {
			margin-right: 0;
		}
	}

	&__close {
		padding: 6px;
		display: flex;
		align-items: center;
		width: 32px;
		height: 32px;
		justify-content: center;
		border: 1px solid $n-75;
		border-radius: 4px;
		background: $white;
		cursor: pointer;

		&:hover {
			background: $n-95;
		}
	}

	&__divider {
		margin: 0 16px;
		height: 32px;
		width: 1px;
		background: $n-80;
	}

	&__title {
		@include font-base-24($n-17, true);
	}

	&__stepper-btn {
		width: 92px;
		white-space: nowrap;

		&--back {
			border-right: none;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			width: 120px;

			&:disabled {
				border: 1px solid $n-75;
				border-right: none;
			}
		}

		&--next {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;

			&:disabled {
				border: 1px solid $n-75;
			}
		}
	}

	&__info {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__tour {
		margin-right: 16px;
		padding: 2px 8px 2px 8px;
		height: 24px;
		background: transparent;
	}

	&__icon {
		margin-right: 4px;
	}

	&__cu-usage {
		display: flex;
		align-items: center;
		@include font-base-12($n-35);
	}

	&__cu-value {
		margin-left: 3px;
		color: $n-17;

		&--exceeded {
			@include font-base-12($r-28, true);
		}
	}

	&__stepper {
		width: 100%;
		display: flex;
		height: 100%;
	}

	&__step {
		display: flex;
		align-items: center;
		height: 100%;

		&--disabled {
			&:hover {
				cursor: not-allowed;
			}

			.test-builder-header__step-wrapper {
				pointer-events: none;
				color: $n-60;
			}
		}
	}

	&__chevron {
		margin: 0 8px;
	}

	&__critical {
		margin-right: 8px;
	}

	&__step-wrapper {
		padding: 8px 12px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		text-transform: capitalize;
		@include font-base-14($n-35, true);
		cursor: pointer;

		&:hover {
			background: $n-90;
			color: $n-17;
		}

		&--active,
		&--active:hover {
			background: $white;
			color: $p-48;
		}
	}

	&__participant-count {
		margin-left: 8px;
		margin-right: 4px;
		padding: 2px 4px;
		border: 1px solid $n-90;
		border-radius: 4px;
		background-color: $white;
		@include font-base-12($n-30, true);

		&--disabled {
			background-color: $n-90;
			border: 1px solid $n-80;
			color: $n-60;
		}
	}

	.app-container--space-between {
		width: calc(100% - 32px);
	}

	.button--margin-left {
		margin-left: 8px;
	}

	&__compute-unit-error {
		padding: 10px 0;
		width: 100%;
		height: 38px;
		display: flex;
		justify-content: center;
		background-color: $r-39;
		@include font-base-14($white, true);

		&-icon {
			margin-right: 8px;

			path {
				fill: $white;
			}
		}
	}

	&__with-error {
		height: auto;
	}
}
