.plan-and-usage {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	&__loader {
		position: absolute;
		top: 250px;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 28px;
		z-index: 9;
	}

	&__non-admin-info {
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__right {
		margin-left: 32px;
		max-width: 368px;
		width: 100%;
		display: flex;
		flex-direction: column;
		row-gap: 32px;
	}
}
