.report-summary {
	&__metrics {
		padding-top: 24px;
	}

	&__metrics-container {
		margin-bottom: 24px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&--reverse {
			flex-direction: row-reverse;
		}

		@include app-grid-medium {
			display: block;

			.metric-table {
				width: 100%;
			}
		}

		@include app-grid-small {
			display: block;
		}
	}

	&__metrics-container-left {
		width: 49%;

		& > div {
			width: 100%;
		}

		&--row {
			display: flex;
			flex-direction: row;
		}

		&--full-width {
			width: 100%;
		}

		@include app-grid-medium {
			width: 100%;
		}

		@include app-grid-small {
			width: 100%;
		}
	}

	@import "./charts/classificatorResults/ClassificatorResults";
	@import "./charts/load/Load";
}
