.api-access {
	@include font-base-14($n-17);

	&__loader {
		position: absolute;
		top: 250px;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 28px;
		z-index: 9;
	}

	&__button {
		&--copy-curl {
			width: 38px;
			height: 100%;
			border-radius: 0 3px 3px 0;
			border-width: 0 0 0 1px;
		}

		&--delete-token {
			margin-left: 8px;
			width: 32px;
		}
	}

	&__curl-block {
		margin: 14px 0 4px 0;
		display: flex;
		border: 1px solid $n-75;
		border-radius: 4px;
	}

	&__curl-text {
		padding: 9px 0 8px 10px;
		width: 100%;
	}

	&__description {
		margin: 20px 0 8px 0;
	}

	&__icon {
		margin: 0 0 0 1px;
	}

	&__info {
		display: flex;
	}

	&__info-icon {
		margin-right: 5px;
	}

	&__info-url {
		margin-top: 14px;
		display: flex;
		text-decoration: none;
		@include font-base-14($p-48, true);

		&:hover {
			color: $n-17;

			svg path {
				fill: $n-17;
			}
		}
	}

	&__no-tokens-warning {
		padding: 32px 0;
		text-align: center;
		@include font-base-14($n-35);
	}

	&__section {
		&--documentation {
			padding: 32px 0;
		}

		&--init-tokens {
			padding: 0 0 32px 0;
			display: flex;
			justify-content: space-between;
		}
	}

	&__title {
		@include font-base-20($n-17, true);
	}

	&__token-info {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__token-button-group-secondary {
		margin: auto 0;
		display: flex;

		.button--secondary {
			white-space: nowrap;
		}
	}
}

@import "../apiAccess/forms/TokenInfoForm";
