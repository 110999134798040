.payment-header {
	margin: auto;
	padding: 0 16px;
	width: calc(100% - 32px);
	max-width: 1168px;
	min-width: 960px;

	&__container {
		position: relative;
		margin: auto;
		height: 72px;
		padding: 17px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $n-90;
	}

	&__profile {
		margin-right: 16px;
		max-width: 200px;
		height: 30px;
		display: flex;
		align-items: center;
	}

	&__user {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		@include font-base-14($n-17);
	}

	&__user-avatar {
		margin-right: 10px;
	}

	&__close-wrapper {
		top: 24px;
		right: 0;
		display: flex;
		text-align: right;

		.tooltip {
			text-align: left;
		}

		.divider {
			margin-right: 16px;
		}
	}

	&__close-icon:hover {
		cursor: pointer;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		.divider {
			margin-left: 16px;
		}
	}

	.divider--vertical {
		height: 54px;
	}
}
