.report-view-script {
	position: relative;
	height: 600px;

	.overflow-guard {
		border-radius: 4px;
		border: 1px solid $n-90;
	}

	.invisible.scrollbar.horizontal {
		display: none;
	}

	.monaco-hover .invisible.scrollbar.vertical {
		display: none;
	}
}

.loader {
	height: 100px;
}
