.success-rate-chart {
	display: flex;
	flex-direction: column;
	row-gap: 9px;

	&__title {
		@include font-base-10($n-35, true);
		text-transform: uppercase;
	}
}
