.company-form {
	&__title {
		@include font-base-20($n-17, true);
	}

	&__row {
		display: flex;
		justify-content: space-between;

		&:last-child {
			margin-bottom: 4px;
		}

		.form-field,
		.dropdown {
			margin: 0;
			width: calc(50% - 8px);
		}
	}

	&__buyer-type {
		margin-bottom: 16px;
	}

	&__buyer-type-label {
		margin-bottom: 3px;
		@include font-base-14($n-17);
	}

	hr {
		margin-bottom: 32px;
		border: 1px solid $n-90;
	}

	.dropdown__toggle-icon {
		top: 50%;
		transform: translateY(-50%);
	}

	//Overwrites
	.banner {
		padding: 8px 16px;
		align-items: flex-start;
	}
}
