.empty-view {
	position: relative;
	padding: 120px 0;
	margin: 0 auto;

	img {
		margin: 0 auto 30px;
		display: block;
	}

	p {
		display: block;
		@include font-base-14($n-35);
		text-align: center;
	}

	span {
		display: block;
		@include font-base-26($n-90, true);
		text-align: center;
		user-select: none;
	}
}
