.billing-form {
	&__actions {
		display: flex;
		align-items: center;
	}

	&__action-button {
		padding-top: 16px;
	}

	&__business-purchase {
		margin-bottom: 11px;
		@include font-base-14($n-17, true);
	}

	&__controls {
		margin-bottom: 15px;
	}

	.form-label .label {
		@include font-base-14($n-17, true);
	}

	&__email {
		margin-bottom: 16px;

		.form-label {
			display: block;
		}
	}

	&__email-label {
		margin-bottom: 8px;
		@include font-base-14($n-17, true);

		&::after {
			content: "*";
			color: $r-39;
		}
	}

	&__email-value {
		@include font-base-14($n-17);
	}

	&__email-edit {
		margin-top: 7px;
		display: block;
		@include font-base-14($p-48, true);
		text-decoration: underline;
		cursor: pointer;
	}

	&__row {
		display: flex;
		justify-content: space-between;

		.form-field,
		.dropdown {
			width: calc(50% - 8px);
		}
	}

	&__back-btn,
	&__save-btn {
		margin-right: 8px;
	}

	.button--sm {
		padding: 8px 12px !important;
		height: 32px !important;

		.loader {
			overflow: hidden;
		}
	}

	.button--md {
		padding: 10px 12px !important;
	}

	&__modal-buttons {
		display: flex;
		justify-content: flex-end;

		button:first-of-type {
			margin-right: 16px;
		}
	}

	.dropdown {
		margin-bottom: 16px;

		&__toggle-icon {
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.form-field {
		margin-bottom: 8px;
	}

	.billing-form__company-information {
		.billing-form__row {
			.dropdown {
				margin-bottom: 8px;
			}
		}
	}
}
