.account-settings-account {
	position: relative;
	margin: 0 auto;
	max-width: 376px;

	&__title {
		margin-bottom: 8px;
		width: 100%;
		display: inline-block;
		@include font-base-14($n-17, true);
		text-align: center;
	}

	&__delete-btn {
		margin-top: 16px;
		width: 100%;
	}

	// Overwrites
	.form-field {
		margin: 24px 0 8px;
	}
}
