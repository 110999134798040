@import "./details/Details";
@import "./subHeader/SubHeader";
@import "./trends/Trends";

.single-test {
	padding-bottom: 32px;

	&__runs-loader {
		min-height: 300px;
		display: flex;
		justify-content: center;
		align-items: center;

		.loader .loader__spinner {
			transform: translate(-50%, 0);
		}
	}

	&__runs-header {
		padding: 32px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.date-picker {
			margin-right: 0px;
		}
	}

	&__runs-header-title {
		@include font-base-20($n-17, true);
	}

	&__test-info {
		padding: 32px 0;
		display: flex;
		column-gap: 32px;
	}

	&__filters-wrapper {
		display: flex;
		column-gap: 8px;
	}

	&__filters {
		position: relative;

		.filter-modal {
			top: 36px;
			right: 0px;
		}
	}

	&__filter-btn {
		position: relative;
		padding-left: 10px !important;
		justify-content: left;

		&--active {
			background-color: $p-90 !important;
			color: $p-37 !important;

			svg path {
				fill: $p-37;
			}
		}

		&--wide {
			width: 120px;
		}

		&--ultrawide {
			width: 100%;
		}
	}

	.date-picker__calendar-container {
		width: 476px;
	}
}
