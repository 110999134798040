.run-list {
	.empty-view span {
		@include font-base-20($n-35, true);
	}

	&__date {
		margin-bottom: 24px;
		padding: 6px 8px;
		border: 1px solid $n-90;
		border-radius: 4px;
		background-color: $y-98;
		@include font-base-14($n-17, true);
	}
}

.run-list + .run-list {
	margin-top: 24px;
}

@import "./runResultCard/RunResultCard";
@import "./runStatusIndicator/RunStatusIndicator";
