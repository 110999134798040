.usage-info {
	max-width: 368px;
	width: 100%;
	padding: 16px 0;
	border: solid 1px $n-90;
	border-radius: 8px;
	flex-grow: 1;

	&__top {
		padding: 0 16px;

		&--draft {
			padding: 0 16px;
			border-bottom: none;
		}

		&--start-trial {
			padding: 0 16px;
		}
	}

	&__title {
		margin-bottom: 3px;
		@include font-base-14($n-35);
	}

	&__details {
		display: flex;
		align-items: center;
	}

	&__plan-type {
		margin-right: 12px;
		@include font-base-20($n-17, true);
		text-transform: capitalize;

		span {
			@include font-base-20($n-35);
			text-transform: none;
		}
	}

	&__time-left {
		padding: 5px 8px;
		border-radius: 4px;
		@include font-base-12($y-14, true);
		background: $y-95;
	}

	&__bottom {
		padding: 16px 16px 0;
	}

	&__cu-used {
		margin-bottom: 14px;
		width: 100%;
		display: flex;
		justify-content: space-between;

		@include font-base-14($n-17);
	}

	&__progress-bar {
		margin-bottom: 10px;
		width: 100%;
		height: 8px;
		border-radius: 8px;
		background: $n-90;
		overflow: hidden;

		&--additional {
			background: $t-17;
		}
	}

	&__inner-bar {
		height: 100%;
		border-radius: 8px;
		background: $g-55;
	}

	&__cu-legend {
		display: flex;
		@include font-base-12($n-17);
	}

	&__legend-item {
		display: flex;
		align-items: center;
		margin-left: 14px;

		&:first-of-type {
			margin-left: 0;
		}

		strong {
			margin-right: 6px;
		}
	}

	&__circle {
		margin-right: 8px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		display: block;
		background: $g-55;

		&--unused {
			background: $n-90;
		}

		&--additional {
			background: $t-17;
		}
	}

	.divider {
		margin-top: 16px;
	}
}
