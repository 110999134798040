.favorites-sub-header {
	padding-bottom: 32px;
	width: 100%;
	display: flex;
	justify-content: right;

	&__filter-btn {
		position: relative;
		padding-left: 11px !important;
		justify-content: left;

		&--active {
			background-color: $p-90 !important;
			color: $p-37 !important;

			svg path {
				fill: $p-37;
			}
		}

		&--wide {
			width: 120px;
		}

		&--ultrawide {
			width: 100%;
		}
	}
}
