.app-header {
	position: sticky;
	top: 0;
	margin: auto;
	padding: 9px 0;
	width: calc(100% - 32px);
	max-width: 1168px;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $n-90;
	background: $white;
	z-index: 9999;

	&__select-wrapper {
		display: flex;
		align-items: center;
		height: 52px;
	}

	&__logo {
		margin: 28px 0 28px;
		width: 117px;
		height: 16px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	&__select {
		position: absolute;
		left: 150px;
		height: 40px;
		max-width: 268px;
	}

	&__project {
		padding: 4px 12px 4px 4px;
		display: flex;
		align-items: center;
		height: 42px;
		width: fit-content;
		max-width: 100%;
		border-radius: 4px;
		@include font-base-14($n-17, true);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;

		&:hover {
			background: $n-95;
		}
	}

	&__menu-arrow {
		margin-left: 8px;
		transform: rotate(180deg);

		path {
			fill: $n-35;
		}
	}

	&__project-name {
		margin-left: 8px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__project-dropdown {
		position: relative;
		top: 0px;
		margin-top: 4px;
		min-width: 216px;
		max-width: 264px;
		min-height: 208px;
		border-radius: 8px;
		box-shadow:
			0 0 1px 0 rgba(45, 45, 57, 0.5),
			0 8px 12px -4px rgba(45, 45, 57, 0.3);
		background: $white;
	}

	&__current-project {
		padding: 12px 8px 8px;
		display: flex;
		flex-direction: column;
	}

	&__current-project-title {
		margin-bottom: 4px;
		padding: 0 8px;
		@include font-base-12($n-35, true);
		text-transform: uppercase;
	}

	&__other-projects-title {
		margin-top: 8px;
		margin-bottom: 4px;
		padding: 0 8px;
		@include font-base-12($n-35, true);
		text-transform: uppercase;
	}

	&__current-project-name {
		margin: 4px 8px 12px;
		display: flex;
		align-items: center;
		height: 32px;
		@include font-base-14($n-17, true);
	}

	&__current-project-menu-item {
		padding: 6px 8px;
		@include font-base-14($n-17);
		border-radius: 4px;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			background: $n-95;
		}
	}

	&__all-projects {
		padding: 8px;
		width: 100%;
		border-top: 1px solid $n-95;
	}

	&__project-list {
		max-height: 300px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	&__btn-wrapper {
		padding: 8px 12px;
	}

	&__btn-icon {
		margin: 4px;

		path {
			fill: $p-48;
		}
	}

	&__project-btn {
		display: flex;
		align-items: center;
		@include font-base-14($p-48, true);
		border-radius: 4px;
		cursor: pointer;

		&:hover {
			background: $p-90;
		}
	}

	&__project-list-item {
		padding: 4px 8px;
		width: 100%;
		min-height: 32px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		@include font-base-14($n-17, true);
		overflow-wrap: break-word;
		cursor: pointer;

		&:hover {
			background: $n-95;

			.app-header__settings-icon {
				display: block;
			}
		}
	}

	&__settings-icon {
		position: absolute;
		top: 12px;
		right: 12px;
		width: 12px;
		height: 12px;
		display: none;

		&:hover path {
			fill: $n-35;
		}
	}

	&__profile {
		position: relative;
		height: 30px;
		display: flex;
		align-items: center;
	}

	&__profile-dropdown {
		position: absolute;
		top: 40px;
		right: 0;
		min-width: 216px;
		max-width: 264px;
		border-radius: 8px;
		box-shadow:
			0 0 1px 0 rgba(45, 45, 57, 0.5),
			0 8px 12px -4px rgba(45, 45, 57, 0.3);
		background: $white;
	}

	&__profile-dropdown-element {
		margin: 0 auto;
		padding: 10px 12px 9px;
		width: 200px;
		height: 36px;
		display: flex;
		border-radius: 4px;
		@include font-base-14($n-17, true);
		text-decoration: none;
		cursor: pointer;

		&:hover {
			background: $n-95;
		}

		.running-loader {
			margin-left: 8px;
		}

		span {
			margin-left: 3px;
		}
	}

	&__block {
		position: relative;
		padding: 4px 12px 4px 4px;
		height: 42px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:hover {
			background: $n-95;
		}
	}

	&__user {
		@include font-base-14($n-17, true);
		overflow-wrap: break-word;
	}

	&__profile-email {
		margin-top: 4px;
		@include font-base-12($n-35);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	&__user-avatar {
		position: relative;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		overflow: hidden;
	}

	&__user-menu {
		margin-right: 12px;
		width: 12px;
		height: 7px;
	}

	&__right {
		display: flex;
		align-items: center;
		height: 52px;
	}

	&__help-menu {
		position: relative;
		margin-right: 16px;
		display: flex;
		justify-content: center;
		width: 32px;
		height: 32px;
		border: 1px solid $n-75;
		border-radius: 4px;
		cursor: pointer;
	}

	&__help-dropdown {
		position: absolute;
		top: 36px;
		right: 0;
		width: 184px;
		background-color: $white;
		border-radius: 8px;
		box-shadow:
			0 0 1px 0 rgba(45, 45, 57, 0.5),
			0 8px 12px -4px rgba(45, 45, 57, 0.3);
	}

	&__help-dropdown-items-wrapper,
	&__profile-dropdown-items-wrapper {
		padding: 8px;
	}

	&__profile-dropdown-item {
		padding: 6px 8px;
		border-radius: 4px;
		@include font-base-14($n-17);
		cursor: pointer;
		text-decoration: none;

		&:hover {
			background: $n-95;
		}

		&-new-tb {
			display: flex;
			align-items: center;
		}

		&-icon {
			margin-left: 4px;
			width: 20px;
			height: 20px;
		}
	}

	&__profile-dropdown-item--user {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&:hover {
			background: $white;
			cursor: default;
		}
	}

	&__help-dropdown-item {
		padding: 6px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 4px;
		@include font-base-14($n-17);
		cursor: pointer;
		text-decoration: none;

		&:hover {
			background: $n-95;
		}
	}

	&__icon-help {
		margin: 5px;

		path {
			fill: $n-17;
		}
	}

	&__icon-help-wrapper {
		width: 100%;
		height: 100%;
		border-radius: 4px;

		&:hover {
			background-color: $n-95;
		}
	}

	.divider {
		margin: 0 16px;
	}

	.divider--horizontal {
		margin: 0;
	}

	.avatar--md {
		&.avatar--project {
			width: unset;
		}

		.avatar__container--project {
			width: 30px;
		}
	}

	.tooltip,
	.tooltip--sm {
		overflow-wrap: break-word;
		white-space: pre-wrap;
		z-index: 9;
	}
}
