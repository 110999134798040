.success-rate-chart {
	position: relative;

	&__loader {
		position: absolute;
		top: 20px;
		z-index: 9;
		background: linear-gradient(0.25turn, transparent, $white, transparent);
		width: 40%;
		height: 32px;
		animation: skeletonAnimation 1.5s;
		animation-iteration-count: infinite;
	}

	&__marker-tooltip {
		text,
		tspan {
			color: $white !important;
			fill: $white !important;
		}

		.highcharts-label-box {
			fill: $n-17 !important;
		}
	}

	.highcharts-title {
		font-size: 10px !important;
		font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
			"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
		line-height: 1.2;
		letter-spacing: 0.4px;
	}

	.highcharts-area {
		transform: scale(1.013) translate(-1px);
	}
}

@keyframes skeletonAnimation {
	0% {
		transform: translate(-40px, 0%);
	}
	100% {
		transform: translate(150px, 0%);
	}
}
