.subscription-settings {
	&__cu-label {
		text-transform: capitalize;

		input {
			text-transform: capitalize;
		}
	}

	&__disabled-dropdown > div > input {
		background-color: $white;
	}

	&__save-button {
		margin-top: 16px;
	}

	&__settings {
		padding: 32px 40px 32px 0;
		display: flex;
		column-gap: 32px;
	}

	&__settings-description {
		@include font-base-12($n-17);
	}

	&__settings-text {
		max-width: 268px;
		width: 100%;
	}

	&__settings-title {
		padding-bottom: 12px;
		@include font-base-14($n-17, true);
	}

	&__settings-value {
		@include font-base-14($n-17);
	}

	&__title {
		@include font-base-20($n-17, true);
	}

	&__settings-input,
	.dropdown__input {
		width: 168px;
	}

	.form-field__label-wrapper {
		margin-bottom: 0;
	}

	.form-field__increment {
		top: 1px;
	}
}

.blur {
	max-width: 568px;
	min-width: 408px;
	width: 100%;

	@include app-grid-small {
		min-width: 448px;
	}

	&__warning-container {
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		max-width: 448px;
		width: 100%;
	}

	&__warning-text {
		margin-left: 4px;
		white-space: pre-line;
	}
}
