.participant-results-logs {
	position: relative;
	padding-bottom: 32px;
	width: 100%;

	&__warnings {
		padding-bottom: 20px;
	}

	.info-text {
		margin-bottom: 4px;
		color: $n-17;
	}

	.participant-results__warning-wrapper {
		padding-top: 0;
		margin-bottom: 4px;
	}
}
