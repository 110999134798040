.tests-list {
	.empty-view span {
		@include font-base-20($n-35, true);
	}

	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

@import "./successRateChart/SuccessRateChart";
