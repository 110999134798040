.participant-card-layout {
	position: relative;
	padding: 16px;
	margin-bottom: 8px;
	border: 1px solid $n-90;
	border-bottom-color: $n-80;
	border-radius: 8px;
	background: $white;

	&__error {
		margin-bottom: 16px;
		padding: 5px 17px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $r-55;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		@include font-base-12($r-28, true);
		background-color: $r-95;

		svg {
			margin-right: 8px;
		}
	}

	&__heading {
		margin-bottom: 12px;
		min-height: 26px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include font-base-14($n-17, true);
	}

	&__title {
		display: flex;
		align-items: center;
		width: 65%;

		@include app-grid-small {
			width: 55%;
		}

		@include app-grid-medium {
			width: 56%;
		}

		.badge {
			flex-shrink: 0;
		}

		.button-group {
			opacity: 0;
		}
	}

	&__name {
		margin-right: 12px;
		max-width: 100%;
		@include font-base-14($n-17, true);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__group-icon {
		padding-right: 12px;
		margin-right: 12px;
		display: flex;
		align-items: center;
		border-right: 1px solid $n-90;
	}

	&__body {
		display: flex;
		@include font-base-12($n-35);
	}

	&__item {
		padding: 0 12px;
		border-left: 1px solid $n-90;

		&:first-of-type {
			padding-left: 0;
			border-left: none;
		}
	}

	.button-group {
		opacity: 0;
	}

	&:hover,
	&:has(.button:focus),
	&:has(.button:active) {
		border-color: $n-60;

		.button-group {
			opacity: 1;
		}
	}

	&--has-error {
		padding: 0;
		box-shadow: none;
		border-color: $r-55;

		.button-group {
			top: 40px;
		}

		&:hover {
			border-color: $r-55;
		}

		.participant-card-layout__wrapper {
			padding: 0 16px 16px;
		}
	}
}
