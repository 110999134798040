.report-asserts {
	position: relative;
	width: 100%;
	display: block;

	&__empty-view img {
		width: 314px;
		height: 262px;
	}

	&__pagination-wrapper {
		display: flex;
		justify-content: center;
	}
}
