.data-chart {
	position: relative;
	border: 1px solid $n-90;
	border-bottom: none;
	cursor: pointer;

	.tooltip-series,
	.tooltip-time {
		font-size: 12px;
	}

	.profile-dropdown {
		position: absolute;
		right: 16px;
		bottom: 55px;
		padding: 8px 0;
		width: 226px;
		height: fit-content;
		border-radius: 4px;
		box-shadow: 0 2px 6px 0 rgba(106, 109, 138, 0.3);
		background: $white;

		div {
			margin: 0 auto;
			padding: 10px 12px 9px;
			width: 200px;
			height: 36px;
			display: block;
			border-radius: 4px;
			@include font-base-14($n-17, true);
			text-decoration: none;
			cursor: pointer;

			&:hover {
				background: $n-95;
			}
		}
	}

	&:first-of-type {
		margin-top: 24px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		padding: 0;
	}

	&:last-of-type {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		box-shadow: 0 7px 5px -5px rgba(106, 109, 138, 0.2);
		border-bottom: 1px solid $n-90;
	}

	&--visible .data-chart__arrow-icon {
		transform: rotate(0deg);
	}

	&__header {
		position: relative;
		padding: 12px 16px 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__name {
		font-size: 22px;
		font-weight: 700;
		color: $n-17;
	}

	&__details {
		font-size: 16px;
		font-weight: 700;
		color: $n-35;
	}

	&__arrow-icon {
		position: absolute;
		right: 16px;
		height: 20px;
		width: 20px;
		transition: transform 0.2s ease-in-out;
		transform: rotate(180deg);
	}

	&__container {
		width: 100%;
		font-size: 10px;

		&:hover {
			z-index: 1;
		}
	}

	&__buttons-wrapper {
		margin-right: 16px;
		display: flex;
		justify-content: flex-end;
	}

	&__button {
		position: relative;
		margin-bottom: 16px;
		padding-left: 32px;
		z-index: 9;
		width: 106px;
		height: 32px;
		display: flex;
		align-items: center;
		@include font-base-14($n-17, true);
		@include button-base($n-95, $n-90, none, 4px);

		&--actions {
			right: 16px;
			padding: 0 8px;
			width: unset;
			justify-content: center;
		}

		.icon.download {
			position: absolute;
			top: 8px;
			left: 8px;
			height: 16px;
			width: 16px;
			display: block;
		}
	}

	&--non-collapsible {
		margin-bottom: 30px;
		border-bottom: 1px solid $n-90;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	//Overwrites
	.highcharts-reset-zoom {
		rect {
			fill: $white;
			stroke: $n-75;
			width: 88px;
			rx: 3;
			ry: 3;
		}

		text {
			fill: $n-17 !important;
			font-weight: bold !important;
			font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
				"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
		}
	}
}
