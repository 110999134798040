.contact-us-modal {
	@include full-size(fixed, 0, 0, 0, 0);
	width: 100%;
	height: 100%;
	background-color: rgba(106, 109, 138, 0.3);
	overflow-y: scroll;
	z-index: 9999999;

	&--success {
		padding: 80px 0;
	}

	&__box {
		position: relative;
		left: 0%;
		margin: 16px auto 60px;
		width: 568px;
		border-radius: 12px;
		box-shadow: 0 4px 11px -6px $n-35;
		background: $white;

		&--success {
			position: absolute;
			left: 50%;
			padding: 80px 0;
			margin: 16px auto 60px;
			width: 424px;
			height: 352px;
			border-radius: 12px;
			display: flex !important;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			box-shadow: 0 4px 12px 0 #4f4f64;
			transform: translate(-50%, 0);
			background: $white;
		}
	}

	&__success-icon {
		width: 96px;
		height: 96px;
		margin-bottom: 24px;

		img {
			width: 100%;
		}
	}

	&__success-heading {
		margin-bottom: 16px;
		width: unset;
		@include font-base-39($n-17, true);
		text-align: center;
		letter-spacing: -0.7px;
	}

	&__success-subtitle {
		@include font-base-16($n-35);
		text-align: center;
		letter-spacing: -0.4px;
	}

	textarea {
		resize: vertical;
		letter-spacing: -0.4px;

		@include app-grid-small {
			resize: none;
		}
	}
}
