img.avatar {
	width: 100%;
	height: 100%;
}

.project-avatar-upload {
	display: flex;

	&__tooltip {
		position: absolute;
		bottom: 0;
		padding-top: 3px;
		width: 100%;
		height: 27px;
		display: none;
		@include font-base-14($white, true);
		background: rgba(106, 109, 138, 0.7);
		text-align: center;
		cursor: pointer;

		&--visible,
		&:hover {
			display: block;
		}
	}

	&__actions {
		position: absolute;
		padding: 8px;
		width: 100%;
		border-radius: 4px;
		box-shadow: 0 2px 6px 0 rgba(106, 109, 138, 0.3);
		background: $white;
		transform: translateY(12px);

		&:before {
			content: "";
			position: absolute;
			top: -7px;
			left: 50%;
			margin-right: -50%;
			transform: translate(-50%, 0);
			width: 0;
			height: 0;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 8px solid $white;
		}

		li {
			position: relative;
			margin: 0 auto;
			padding: 10px 12px;
			border-radius: 4px;
			display: block;
			@include font-base-14($n-17, true);
			letter-spacing: 0;
			text-decoration: none;
			background-color: $white;
			cursor: pointer;

			&:hover {
				background-color: $n-95;
			}

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				cursor: pointer;
			}
		}
	}

	&__file-input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		width: 119px;
	}

	&__buttons-wrapper {
		margin-left: 24px;
		display: flex;
		align-items: center;

		::-webkit-file-upload-button {
			cursor: pointer;
		}

		.button {
			margin-right: 8px;
			padding: 2px 8px;
			height: 24px;
		}
	}

	.divider {
		background-color: $n-90;
	}

	.modal {
		&__box {
			position: relative;
			left: 0%;
			width: 568px;
			max-height: 1000px;
			margin: 16px auto 60px;
			border-radius: 12px;
			box-shadow: 0 4px 11px -6px $n-35;
			background: $white;
		}

		&__crop-tool {
			display: flex;
			justify-content: center;
		}

		&__action {
			display: flex;
			justify-content: flex-end;
		}

		&__cancel-button {
			margin-right: 8px;
		}

		&__title {
			padding: 16px;
		}
	}

	.loader {
		position: relative;
	}

	.ReactCrop__child-wrapper {
		max-height: 700px;
	}
}
