.account-settings {
	position: relative;
	margin: 0 auto;
	padding: 16px 0 0;
	max-width: 776px;
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;

	&__title {
		@include font-base-32($n-17, true);
	}

	&__content {
		padding-bottom: 80px;
		flex: 1;
	}

	&__version {
		position: relative;
		bottom: 0;
		padding: 18px 0 10px;
		width: 100%;
		text-align: center;
		@include font-base-10($n-75);
	}

	.tab-navigation{
		margin: 30px 0 24px 0;
	}
}

@import "./account/Account";
@import "./password/Password";
@import "./profile/Profile";
