.plan-overview {
	max-width: 368px;
	width: 100%;

	&__actions {
		display: flex;
		justify-content: center;
		column-gap: 8px;
	}

	&__btn {
		width: 100%;
		min-width: 123px;
	}

	&__data-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__data-wrapper {
		padding: 16px;
		border: 1px solid $n-90;
		border-radius: 8px;
	}

	.divider {
		margin-top: 11px;
		margin-bottom: 12px;
	}

	&__name {
		@include font-base-14($n-35);
	}

	&__status {
		display: flex;
		align-items: center;
		column-gap: 4px;
	}

	&__title {
		margin-bottom: 16px;
		@include font-base-20($n-17, true);
	}

	&__value {
		@include font-base-14($n-17);

		&:first-letter {
			text-transform: capitalize;
		}
	}

	&__skip-renewal-modal {
		.modal__container--large-padding {
			padding: 24px 16px;
		}
	}
}
