.exit-modal {
	&__title {
		@include font-base-20($n-17, true);
	}

	&__message {
		@include font-base-14($n-17);
	}

	// Overwrites
	.exit-modal__btn {
		margin-right: 8px;
	}
}
