.group-modal {
	&__section {
		width: 100%;
		display: flex;
	}

	&__title {
		margin-right: 16px;
		width: 360px;
	}

	&__count {
		flex: 1;
	}

	&__cancel-btn {
		margin-right: 8px;
	}
}
