@import "~react-toastify/dist/ReactToastify.min.css";

.alert-container {
	// reversing the order of toasts
	display: flex;
	flex-direction: column-reverse;
	//
	top: 82px;
	right: 21px;
	width: auto;
	max-width: 360px;
}

.Toastify {
	position: absolute;
	z-index: 99999999;
}

.Toastify__toast--success {
	background: $g-55;
}

.Toastify__toast--error {
	background: $r-55;
}

.Toastify__toast--info {
	background: $p-48;
}

.alert-box {
	margin-bottom: 8px;
	padding: 12px 48px 12px 12px;
	min-height: 40px;
	min-width: 252px;
	border-radius: 4px;
	border: 1px solid $white;
	float: right;
	box-shadow: none;
	@include font-base-14($white, true);
	font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

	.link-button {
		border: none;
		@include font-base-14($white, true);
		background: none;
		text-decoration: underline;
		white-space: nowrap;
		cursor: pointer;

		&:hover {
			text-decoration: none;
			color: $n-17;
		}
	}

	.alert-close {
		position: absolute;
		top: 15px;
		right: 14px;
		height: 14px;
		width: 14px;

		&:hover path {
			fill: $white;
			fill-opacity: 1;
		}
	}

	.alert-progress-bar {
		height: 4px;
		background-color: rgba(255, 255, 255, 0.7);
	}

	.Toastify__toast-icon {
		svg {
			fill: rgba(255, 255, 255, 0.7);
		}
	}
}
