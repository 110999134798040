.members {
	position: relative;
	margin-bottom: 52px;

	&__info {
		margin-bottom: 8px;
		display: flex;
		justify-content: space-between;
	}

	&__title {
		@include font-base-22($n-17, true);
	}

	&__add-member-btn {
		position: relative;
		padding-left: 26px;

		&:before {
			content: "+";
			position: absolute;
			top: 6px;
			left: 12px;
			color: $white;
		}
	}

	.help-text {
		margin-top: 8px;
	}

	&__list {
		margin-top: 32px;
	}

	&__list-row {
		padding: 16px 0;
		border-bottom: 1px solid $n-95;
		display: flex;
		align-items: center;

		&:first-of-type {
			border-top: 1px solid $n-95;
		}
	}

	&__remove {
		position: relative;
		padding: 0 8px 0 24px;
		min-width: 64px;
	}

	&__remove-btn {
		padding: 0px;
		width: 32px;
		height: 32px;

		svg {
			margin: 0px;
		}
	}

	&__user {
		padding-left: 16px;
		flex: 1;
		@include font-base-14($n-17);
	}

	&__name {
		font-weight: 700;
	}

	&__status {
		text-transform: lowercase;
	}

	&__role {
		width: 172px;

		&--value {
			display: block;
			text-align: right;

			&::first-letter {
				text-transform: capitalize;
			}
		}

		.form-select {
			margin-bottom: 0;

			&.disabled select {
				padding-right: 0;
				text-align-last: right;
			}
		}
	}
}

.member-form {
	&__inputs {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.form-field {
			margin-bottom: 0;
			width: 100%;
			max-width: 364px;
		}
	}

	&__title {
		@include font-base-22($n-17, true);
	}

	&__role {
		position: relative;
		margin: 28px 0 0 16px;
		height: 37px;
		width: 250px;

		select {
			padding: 10px 16px;
			font-weight: 700;
			color: $n-17;
			font-family: inherit;
		}
	}

	&__cancel-btn {
		margin-right: 8px;
	}
}
