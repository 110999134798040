.test-card {
	background-color: $white;
	border-radius: 8px;
	box-shadow:
		0 1px 0 0 $n-80,
		0 -1px 0 0 $n-90,
		1px 0 0 0 $n-90,
		-1px 0 0 0 $n-90;

	&:hover {
		cursor: pointer;

		.test-card__favorite {
			visibility: visible;
			cursor: pointer;
		}
	}

	&__actions {
		padding: 4px 0;
		margin-left: 32px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		row-gap: 8px;
	}

	&__action-menu {
		position: relative;
	}

	&__buttons {
		display: flex;
		column-gap: 8px;
		height: 24px;
	}

	&__body {
		padding: 12px 16px 0;
		display: flex;
		justify-content: space-between;
	}

	&__body-left {
		padding: 4px 0;
		display: flex;
		flex-direction: column;
		row-gap: 12px;
		overflow: hidden;
	}

	&__body-right {
		display: flex;
	}

	&__body-right .test-card__icon-btn {
		padding: 0px !important;
		width: 24px;

		svg {
			margin-right: 0 !important;

			path {
				width: 13px;
			}
		}
	}

	&__footer {
		padding: 0 16px 12px;
	}

	&__header {
		padding: 8px 16px;
		height: 36px;
		display: flex;
		align-items: center;
		background-color: $y-98;
		border-bottom: 1px $n-90 solid;
		border-radius: 8px 8px 0 0;
	}

	&__last-run {
		display: flex;
		justify-content: right;
		white-space: nowrap;
	}

	&__last-run-date {
		padding-left: 4px;
		@include font-base-12($n-17, true);
	}

	&__cu-value,
	&__participants-value {
		@include font-base-12($n-17, true);
	}

	&__last-run-title {
		@include font-base-12($n-17);
	}

	&__cu-title,
	&__mode-title,
	&__participants-title {
		padding-left: 4px;
		@include font-base-12($n-17);
	}

	&__name-section {
		display: flex;
		padding-right: 16px;
		height: 20px;
	}

	&__name {
		padding-top: 1px;
		padding-right: 8px;
		@include font-base-14($n-17, true);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__favorite {
		visibility: hidden;

		&--active {
			visibility: visible;
		}
	}

	&__status {
		padding-left: 12px;
		@include font-base-14($n-17);
	}

	&__participants,
	&__cu,
	&__mode,
	&__metadata {
		display: flex;
		align-items: center;
		text-align: center;
	}

	&__round-separator {
		padding: 0 8px;
		@include font-base-12($n-17, true);
	}

	&__start-test {
		padding-left: 6px !important;
		width: 91px;
		font-size: 14px !important;
	}

	&__labels {
		margin-right: 32px;
		display: flex;
		overflow-x: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}

		.label {
			margin-right: 8px;
		}
	}

	.button {
		white-space: nowrap;
	}

	.button:disabled {
		svg {
			path {
				fill: $n-60;
			}
		}
	}

	.tooltip {
		padding: 4px 8px !important;
		font-size: 12px !important;
		line-height: 16px !important;

		&::before {
			z-index: -1 !important;
		}
	}
}

@import "./simpleSuccessRateChart/SimpleSuccessRateChart";
