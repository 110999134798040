.address-form {
	&__country {
		margin-bottom: 18px;
	}

	&__title {
		@include font-base-20($n-17, true);
	}

	&__row {
		display: flex;
		justify-content: space-between;

		&:last-child {
			margin-bottom: 4px;
		}
	}

	.form-field {
		margin-bottom: 10px;
	}

	.address-form__row {
		.form-field {
			width: calc(50% - 8px);
		}
	}

	//Overwrites
	.form-field:last-of-type {
		margin-bottom: 0;
	}

	&__dropdown {
		.dropdown__input {
			.dropdown__toggle-icon {
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.banner {
		padding: 8px 16px;
		align-items: flex-start;
	}
}
