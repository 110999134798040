.participant-results-asserts {
	position: relative;
	width: 100%;

	&__table {
		margin-bottom: 32px;
		width: 100%;
		border-collapse: collapse;
	}

	&__table-head-row {
		background: $n-95;
	}

	&__table-row {
		min-height: 49px;
		border-bottom: 1px solid $n-95;
		word-wrap: break-word;

		&:hover {
			background: $y-98;
		}
	}

	&__table-head-cell {
		padding: 12px 16px;
		border: none;
		@include font-base-10($n-35, true);
		text-transform: uppercase;
		text-align: left;
	}

	&__table-cell {
		padding: 16px;
		@include font-base-14($n-17);
		text-align: left;

		&--status {
			position: relative;
			padding-left: 23px;
			width: 1%;
			white-space: nowrap;
		}

		&--skipped svg {
			top: 44%;
			width: 18px;
			height: 22px;
		}
	}

	&__status-icon {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(0, -50%);
		width: 18px;
		height: 18px;
	}

	&__pagination-wrapper {
		display: flex;
		justify-content: center;
	}
}
