.assert-card-display {
	&__main {
		position: relative;
		padding: 10px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&--collapsible {
			cursor: pointer;
		}

		&--open {
			box-shadow: 0 1px 1px 0 rgba(38, 38, 48, 0.27);
			border-radius: 8px 8px 0 0;
		}
	}

	&__constructed-path {
		display: flex;
		align-items: center;
		@include font-base-14($n-17);

		li {
			margin-left: 12px;

			&:first-of-type {
				margin-left: 0;
			}
		}
	}

	&__path {
		.badge--lg {
			height: unset;
			word-break: break-all;
		}
	}

	&__operator {
		.badge--lg {
			word-break: normal;
		}
	}

	&__collapse-icon {
		margin-left: 16px;
		cursor: pointer;

		path {
			fill: $n-60;
		}

		&--up {
			transform: rotate(180deg);
		}
	}

	&__controls {
		display: flex;
		align-items: center;
	}

	&__precondition-icon {
		display: flex;
		align-items: center;

		svg {
			margin-right: 4px;
		}
	}

	&__precondition-count {
		margin-left: 4px;
		@include font-base-12($n-35);
	}

	&__truncated-badge {
		max-width: 460px;
		word-break: break-word;

		.badge {
			height: unset;
		}
	}

	&__preconditions {
		max-height: 0;
		height: auto;
		display: flex;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		overflow: hidden;
		background: $y-98;

		&--open {
			padding-top: 12px;
			max-height: unset;
			height: auto;
			box-shadow:
				inset 0 -1px 0 0 $n-80,
				inset 0 1px 0 0 $n-90,
				inset 1px 0 0 0 $n-90,
				inset -1px 0 0 0 $n-90;
		}
	}

	&__precondition {
		display: flex;
		align-items: baseline;
	}

	&__preconditions-left {
		width: 68px;
	}

	&__preconditions-right {
		flex: 1;
		display: flex;
		flex-wrap: wrap;

		.tag {
			margin-right: 12px;
			margin-bottom: 12px;
		}
	}

	&__arrow-icon {
		margin: -11px auto 0;
		display: block;
		transform: scale(1.2);
	}

	&__word {
		margin-right: 12px;
		@include font-base-14($n-17);
	}

	&__tag-wrapper--disabled {
		.tag__right {
			cursor: not-allowed;

			svg {
				cursor: not-allowed;
			}
		}
	}

	&--error {
		box-shadow: none;
		border-color: $r-55;

		&:hover {
			border-color: $r-55;
		}
	}

	&__tag-wrapper--disabled {
		.tag__right {
			cursor: not-allowed;

			svg {
				cursor: not-allowed;
			}
		}
	}

	&--viewer {
		.tag__left {
			padding-right: 12px;
		}

		.tag__right {
			display: none;
		}
	}

	.tag__left {
		word-break: break-all;
	}
}
