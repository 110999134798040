.project-details {
	width: 100%;

	&__section-wrapper {
		display: flex;
		padding: 32px 0;

		&--name {
			padding-top: 4px;
			padding-bottom: 24px;
		}
	}

	&__title {
		margin-right: 32px;
		width: 268px;
		transition: all 0.3s ease;

		@include font-base-14($n-17, true);

		&--delete {
			@include font-base-14($r-39, true);
		}

		@include app-grid-small {
			width: 208px;
		}
	}

	&__delete-text {
		margin-top: 10px;
		@include font-base-12($n-35);
	}

	&__content {
		width: 368px;
		@include font-base-14($n-17);

		&--name {
			display: flex;
			width: 400px;
		}
	}

	&__name-input {
		padding: 8px 12px;
	}

	&__button-delete {
		margin-top: 32px;
	}

	&__arrow-delete {
		display: flex;
		justify-content: flex-end;
		flex-grow: 1;

		svg {
			width: 20px;
			height: 20px;
			transform: rotate(-180deg);
			transition: transform 0.1s linear;
			cursor: pointer;
		}

		&--toggle svg {
			transform: rotate(0deg);
			transition: transform 0.1s linear;
		}
	}

	//Overwrites

	.form-field {
		margin-bottom: 0;

		&__label-wrapper {
			margin-bottom: 0;
		}

		&__input {
			margin-right: 10px;
			width: 368px;
		}
	}

	.dropdown__input input {
		width: 368px;
	}
}
