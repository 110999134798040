.view-script-modal {
	position: relative;
	padding: 0 16px;
	@include full-size(fixed, 0, 0, 0, 0);
	width: 100%;
	height: 100%;
	background-color: rgba(106, 109, 138, 0.3);
	overflow-y: scroll;
	z-index: 9999999;

	&__box {
		left: 50%;
		margin: 16px auto 60px;
		width: 100%;
		max-width: 968px;
		border-radius: 12px;
		box-shadow: 0 4px 11px -6px $n-35;
		background: $white;
		transition: 0.8s;

		&--expanded {
			width: 100%;
			max-width: 1168px;
			transition: all 0.8s ease;
		}

		@include app-grid-wide {
			.view-script-modal__box--expanded {
				margin: 45px auto;
			}
		}

		@include app-grid-small {
			overflow: scroll;
			width: 928px;
		}

		.view-script-modal__box--expanded {
			width: 1168px;
		}
	}

	&__close {
		margin-right: 16px;
		display: flex;
		justify-content: right;
	}

	&__resize {
		position: relative;
		padding-left: 12px;
	}

	&__icon-wrapper {
		padding: 2px;
		background-color: $y-98;
		border: 1px solid $n-90;
		border-radius: 4px;

		&:hover + .preview-tooltip {
			display: block;
		}
	}

	&__close-icon {
		position: absolute;
		top: 24px;
		right: 24px;
		width: 20px;
		height: 20px;
		display: flex;
		cursor: pointer;
	}

	&__expand-icon {
		width: 20px;
		height: 20px;
		display: flex;
		cursor: pointer;
	}

	&__language {
		margin-left: 8px;
		min-width: 170px;
		@include font-base-14($n-35);
	}

	&__script-block {
		margin: 0 16px;
		border: 1px solid $n-80;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		overflow: hidden;
		height: 85%;
	}

	&__subtitle {
		margin: 0 16px;
		padding: 12px;
		height: 40px;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		border: 1px solid $n-80;
		border-bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include font-base-14($n-17, true);
	}

	&__subtitle-right {
		display: flex;
		align-items: center;
	}

	&__test-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__preview-tooltip {
		position: absolute;
		top: -31px;
		right: -17px;
		padding: 4px 8px;
		height: 22px;
		border-radius: 2px;
		display: none;
		@include font-base-12($white, true);
		text-align: center;
		background-color: rgba(106, 109, 138, 0.8);
		white-space: nowrap;
		transition: 0.5s;

		&::before {
			@include pseudo();
			bottom: -6px;
			left: calc(50% - 5px);
			width: 0;
			height: 0;
			border-top: 6px solid rgba(106, 109, 138, 0.8);
			border-right: 5px solid transparent;
			border-left: 5px solid transparent;
		}
	}

	&__title {
		padding-left: 16px;
		width: 100%;
		@include font-base-20($n-17, true);
	}

	&__loader-wrapper {
		margin: 0 auto;
		width: calc(100% - 32px);
		height: 140px !important;
		border: 1px solid $n-80;
		border-radius: 0 0 8px 8px;
	}

	.divider {
		margin: 16px 0px;
		background-color: $n-90;
	}

	.loader {
		position: static;
		height: 140px;
	}

	.modal__container {
		padding: 16px 0;
	}

	.monaco-scrollable-element.editor-scrollable {
		width: calc(100% - 62px) !important;
	}

	.invisible.scrollbar.horizontal {
		display: none;
	}

	.monaco-hover .invisible.scrollbar.vertical {
		display: none;
	}
}

.monaco-scrollable-element > .visible,
.monaco-scrollable-element > .invisible {
	background: $white !important;
	transition: none !important;
	opacity: 1 !important;
}

.minimap.slider-mouseover {
	left: unset !important;
	right: 14px !important;
}

.monaco-editor.no-user-select,
.overflow-guard {
	width: 100% !important;
}
