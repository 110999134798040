.test-builder-script-layout {
	width: 100%;
	margin-bottom: 22px;

	&__info {
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		column-gap: 16px;
		@include font-base-14($n-17);

		&--column {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__info-url {
		margin-left: 0;
		display: inline-flex;
		align-items: center;
		@include font-base-14($p-48, true);

		&:hover {
			text-decoration: underline;
			color: $n-17;

			svg path {
				fill: $n-17;
			}
		}

		&--no-margin {
			margin-left: 0;
		}
	}

	&__info-icon {
		margin-right: 5px;
	}

	&__editor-wrapper {
		margin-top: 32px;
		border: 1px solid $n-75;
		border-radius: 8px;
		overflow: hidden;

		&--error {
			margin-top: 8px;
			border-color: $r-39;
		}
	}

	&__editor-language {
		padding: 10px 16px;
		border-bottom: 1px solid $n-80;
		@include font-base-14($n-35);
	}

	&__editor {
		min-height: 441px;
	}

	&__error {
		margin-top: 16px;
		display: flex;
		align-items: center;
		@include font-base-14($r-39, true);
	}

	&__critical {
		margin-right: 4px;
	}

	// Overwrites
	.decorationsOverviewRuler {
		right: 14px !important;
	}

	.monaco-scrollable-element.editor-scrollable {
		width: calc(100% - 65px) !important;
	}

	.invisible.scrollbar.horizontal {
		display: none;
	}

	.monaco-hover .invisible.scrollbar.vertical {
		display: none;
	}
}
