.assign-label-modal {
	position: absolute;
	z-index: 9;
	top: 28px;
	right: 0;
	width: 368px;
	border-radius: 8px;
	background-color: $white;
	box-shadow:
		0 0 1px 0 rgba(45, 45, 57, 0.5),
		0 8px 12px -4px rgba(45, 45, 57, 0.3);

	&__back-button {
		padding: 4px 2px 4px 4px !important;
		width: 24px;
	}

	&__footer {
		padding: 8px 16px;
		display: flex;
		justify-content: right;
		column-gap: 8px;
	}

	&__header {
		padding: 10px 16px;
		display: flex;
		align-items: center;
		column-gap: 16px;
	}

	&__body {
		position: relative;
	}

	&__title {
		@include font-base-20($n-17, true);
	}

	.form-field {
		margin: 0 8px;
	}

	.form-field__input {
		padding: 6px 26px 6px 40px;
		width: 352px;
	}

	&__search-icon {
		position: absolute;
		top: 14px;
		left: 20px;
		cursor: pointer;
	}

	&__close-icon {
		position: absolute;
		top: 17px;
		right: 16px;
		cursor: pointer;
	}

	&__labels-wrapper {
		margin-bottom: 8px;
	}

	&__labels {
		padding: 0 8px;
		max-height: 256px;
		overflow-y: scroll;
	}

	&__enter-text {
		padding: 20px 70px 12px;
		text-align: center;
		@include font-base-14($n-35);
	}

	&__create-label {
		padding: 8px 8px 0 8px;
		overflow: hidden;

		.button {
			padding-left: 8px;
			max-width: 352px;

			svg path {
				fill: $p-48;
			}
		}
	}

	&__create-label-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__label-color {
		margin-right: 8px;
		width: 16px;
		height: 16px;
		border-radius: 4px;
		border: 1px solid rgba(38, 38, 48, 0.2);
	}

	&__label-name {
		width: 290px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__label {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.loader--centered .loader__spinner {
		transform: translate(-50%, 50%);
		height: 60px;
	}
}
