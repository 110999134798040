@import "react-day-picker/dist/style.css";

.date-select-calendar {
	.DayPicker-Caption div {
		text-align: center;
		@include font-base-14($n-17, true);
	}

	.DayPicker-WeekdaysRow {
		display: none;
	}

	.DayPicker-NavButton {
		height: 16px;
		width: 16px;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: none;

		&--prev {
			left: 1.5em;

			&:before {
				content: "";
				position: absolute;
				left: 5px;
				top: 3px;
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-right: 5px solid $n-35;
			}
		}

		&--next {
			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid $n-35;
			}
		}

		&--interactionDisabled {
			display: none;
		}

		&:hover {
			background-color: $n-95;
			opacity: 1;
		}
	}

	.DayPicker-Day {
		margin: 2px;
		padding: 0;
		height: 24px;
		width: 24px;
		border: solid 1px transparent;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		@include font-base-12($n-17);

		&:not(&--interactionDisabled),
		&:not(&--disabled),
		&:not(&--selected),
		&:not(&--outside) {
			&:hover {
				border: solid 1px $n-95;
				border-radius: 4px;
				background-color: $white !important;
			}
		}

		&--today {
			color: $p-48;
			font-weight: 700;
		}

		&--selected {
			&:not(&--disabled),
			&:not(&--outside) {
				border: solid 1px $n-95;
				border-radius: 4px;
				background-color: $n-95 !important;
				font-weight: 700;
				color: $n-17 !important;
			}
		}

		&--future {
			color: $n-90 !important;
			pointer-events: none;
		}

		&--outside {
			color: $n-90 !important;
		}
	}
}
