.no-backdrop {
	position: static !important;
	z-index: 9;
}

.copy-modal {
	position: absolute !important;
	left: unset !important;
	z-index: 9;
	width: 386px !important;
	border-radius: 8px !important;
	box-shadow:
		0 0 1px 0 rgba(45, 45, 57, 0.5),
		0 8px 12px -4px rgba(45, 45, 57, 0.3) !important;
	transform: translate(-50%, 0);
	cursor: default;

	.modal__container--copy-header {
		padding: 10px 16px;
	}

	.modal__container--copy-body {
		padding: 20px 16px 12px;
	}

	.modal__container--copy-footer {
		padding: 8px 16px;
	}
}
