.step-selector {
	display: flex;
	justify-content: space-between;

	&__step {
		margin-left: 24px;
		display: flex;
		align-items: center;
		@include font-base-14($n-35);
		cursor: pointer;

		&:first-of-type {
			margin-left: 0;
		}

		&--active {
			@include font-base-14($n-17, true);
		}

		&--disabled {
			cursor: not-allowed;
		}

		&:not(.step-selector__step--disabled):not(.step-selector__step--active):hover {
			@include font-base-14($n-17);
		}
	}

	&__number {
		margin-right: 12px;
		height: 24px;
		width: 24px;
		border: 1px solid $n-17;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
