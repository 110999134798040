.test-builder-tour-banner {
	position: relative;
	min-width: 288px;
	max-width: 368px;
	border-radius: 8px;
	box-shadow: inset 0 -1px 0 0 $n-80, inset 0 1px 0 0 $n-90,
		inset 1px 0 0 0 $n-90, inset -1px 0 0 0 $n-90;

	&__header {
		height: 220px;
	}

	&__close {
		position: absolute;
		top: 27px;
		right: 27px;
		cursor: pointer;

		path {
			fill: $n-35;
		}
	}

	&__body {
		padding: 11px 24px 24px;
	}

	&__title {
		margin: 12px 0 17px;
		@include font-base-20($n-17, true);
	}

	&__text {
		margin-bottom: 16px;
		@include font-base-14($n-17);
	}

	&__image {
		width: 100%;
		height: 100%;
		background: url("../../../../assets/images/tourBanner.svg") no-repeat;
		background-size: contain;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-top: 1px solid #e2e2e8;
	}

	@include app-grid-small {
		width: 100%;
	}

	// Overwrites
	.button {
		width: 100%;
		font-size: 14px;
	}
}
