@import "./subHeader/SubHeader";

.favorites {
	padding-top: 16px;

	.empty-view img {
		width: 368px;
	}

	.extended-pagination {
		padding: 32px 0;
	}
}
