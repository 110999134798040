.toggleable-table {
	width: 100%;
	display: flex;
	flex-direction: column;

	&__toggle {
		padding: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		@include font-base-12($p-37, true);
		text-decoration: underline;
		cursor: pointer;

		&:hover {
			background-color: $y-98;
			color: $n-17;
		}
	}

	&__expandable-row {
		width: 100%;
	}

	&__header {
		margin: 4px 0;
		padding: 8px 6px;
		display: flex;
		white-space: nowrap;
		cursor: pointer;

		&:hover {
			background-color: $y-98;

			.toggleable-table__more-btn {
				color: $n-17;
			}
		}
	}

	&__col {
		padding-left: 16px;
		display: flex;
		align-items: center;

		&:first-of-type {
			padding-left: 0;
			min-width: 130px;
		}
	}

	&__more-btn {
		padding-left: 10px;
		@include font-base-12($p-37, true);
		text-decoration: underline;
		cursor: pointer;
	}

	&__details {
		padding: 4px 16px;
		background-color: $n-95;
		color: $n-35;
	}
}
