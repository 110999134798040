.empty-list {
	padding-top: 176px;
	width: 100%;
	text-align: center;

	&__image {
		margin-bottom: 32px;
	}

	&__title {
		@include font-base-20($n-35, true);
	}

	&__subtitle {
		@include font-base-14($n-35);
	}

	p {
		margin: 0;
	}
}
