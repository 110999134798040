.precondition-list {
	position: relative;
	display: flex;
	align-items: center;

	&__add-btn {
		margin-right: 12px;
		padding: 2px 8px;
		display: flex;
		align-items: center;
		border-radius: 4px;
		@include font-base-14($p-48, true);
		cursor: pointer;
		transition: all 0.3s ease;

		&:hover {
			background: $p-90;
			color: $p-37;
		}

		&--disabled {
			color: $n-60;
			cursor: not-allowed;

			.precondition-list__add-icon path {
				fill: $n-60;
			}

			&:hover {
				background: inherit;
				color: $n-60;
			}
		}
	}

	&__add-icon {
		margin-right: 6px;

		path {
			fill: $p-48;
		}
	}

	&__alert-icon {
		margin-right: 4px;

		path {
			fill: $r-28;
		}
	}

	&__connection {
		position: absolute;
		top: 0;
		left: 18px;
		width: 29px;
		height: 30px;
		border: 2px solid $n-80;
		border-radius: 9px;
		border-top: $white;
		border-top-left-radius: 0;
		border-right: none;
		border-bottom-right-radius: 0;

		&--increased-height {
			height: 52px;
		}
	}

	&__container {
		padding-left: 58px;

		&--padding-top {
			padding-top: 7px;
		}
	}

	&__error-msg {
		@include font-base-14($r-28, true);
	}

	&__fields-wrapper {
		padding-top: 12px;
		margin-bottom: 12px;

		&--error {
			display: flex;
			padding: 0 12px 12px 8px;
		}

		&--invalid {
			border: 1px solid;
			border-radius: 8px;
			background: $r-95;
			border-color: $r-55;
		}
	}

	&__help-icon {
		margin-right: 17px;
	}

	&__new {
		margin-top: 12px;
		margin-bottom: 7px;
		display: flex;
	}
}
