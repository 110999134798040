.run-status-indicator {
	position: relative;
	display: flex;
	text-align: center;
	justify-content: center;

	&__chart {
		position: absolute;
		top: 0px;
		z-index: -1;
		width: 50px;
		height: 50px;
		display: flex;
	}

	&--done {
		position: relative;
		margin-left: 10px;
		margin-right: 12px;
		width: 50px;
		height: 50px;
		flex-shrink: 0;
	}

	&--initializing,
	&--running {
		width: 72px;
		height: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-shrink: 0;
	}

	&--initializing {
		justify-content: space-evenly;
	}

	&--running {
		justify-content: space-between;
	}

	&__slice {
		position: absolute;
		stroke-width: 4px;
		stroke-dasharray: 170;
		fill: transparent;
		transform: rotate(3deg);
	}

	&__slider {
		position: relative;
		width: 70px;
		height: 4px;

		&--background {
			width: 100%;
			height: 100%;
			border-radius: 4px;
			background-color: $p-90;
		}

		&--thumb {
			position: absolute;
			width: 28px;
			height: 100%;
			border-radius: 4px;
			background-color: $p-48;
			animation: sliderAnimation 1.05s;
			animation-iteration-count: infinite;
		}
	}

	&__time,
	&__title--running {
		@include font-base-12($n-17);
		text-transform: capitalize;
	}

	&__title {
		&--done {
			position: absolute;
			top: 50%;
			left: 50%;
			@include font-base-12($n-17, true);
			transform: translate(-50%, -50%);
			letter-spacing: -0.5px;
			text-shadow:
				-0.7px -0.7px 0 #fff,
				0.7px -0.7px 0 #fff,
				-0.7px 0.7px 0 #fff,
				0.7px 0.7px 0 #fff;
		}
	}
}

@keyframes sliderAnimation {
	0%,
	75%,
	100% {
		transform: translate(0%, 0%);
	}
	25%,
	50% {
		transform: translate(42px, 0%);
	}
}
