.assert-card {
	margin-top: 16px;
	width: 100%;
	border-radius: 8px;
	box-shadow:
		inset 0 -1px 0 0 $n-80,
		inset 0 1px 0 0 $n-90,
		inset 1px 0 0 0 $n-90,
		inset -1px 0 0 0 $n-90;

	&:first-child {
		margin-top: 0;
	}

	&__error {
		padding: 5px 17px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $r-55;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		@include font-base-12($r-28, true);
		background-color: $r-95;
	}

	&__critical-icon {
		margin-right: 8px;

		path {
			fill: $r-28;
		}
	}

	&__top {
		padding: 16px 12px;
	}

	&__metric-path-list {
		margin-bottom: 8px;
		display: flex;
		align-items: center;
	}

	&__metric-path-elem {
		margin-left: 13px;

		&:first-child {
			margin-left: 0;
		}

		&--text {
			@include font-base-14($n-17);
		}

		&--dd {
			width: 142px;

			@include app-grid-wide {
				width: 170px;
			}
		}

		&--aggregator {
			@include app-grid-small {
				width: 136px;
			}
		}

		&--statistic {
			@include app-grid-small {
				width: 148px;
			}
		}

		&--input {
			position: relative;
			flex: 1;

			input {
				padding-right: 61px;
			}
		}
	}

	&__divider {
		height: 100%;
		width: 100%;
		border-top: 1px solid $n-90;
	}

	&__bottom {
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__button-group {
		display: flex;
	}

	&__save-btn {
		margin-right: 8px;
	}

	&__tooltip-btn-wrapper {
		display: inline-block;
	}

	&__unit {
		position: absolute;
		top: 6px;
		right: 11px;
		@include font-base-14($n-17);
	}

	.button-group {
		display: none;
	}

	&:hover {
		border-color: $n-60;

		.button-group {
			margin-left: 16px;
			min-width: 124px;
			display: flex;
		}
	}

	.dropdown input {
		padding-right: 34px;
	}

	&--edit {
		height: unset;
		box-shadow:
			0 0 1px 0 rgba(45, 45, 57, 0.5),
			0 8px 12px -4px rgba(45, 45, 57, 0.3);
	}

	&--error {
		border: 1px solid $r-55;
		box-shadow: none;

		&:hover {
			border-color: $r-55;
		}
	}

	&--viewer {
		&:hover {
			.button-group {
				margin-left: 0;
				display: none;
			}
		}
	}

	// Rewrites
	.form-field__error-field,
	.dropdown__error,
	.section-dropdown__error,
	.form-field__label-wrapper {
		display: none;
	}

	.dropdown__list-item-content {
		word-break: keep-all;
	}
}
