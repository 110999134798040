.filter-modal {
	position: absolute;
	top: 52px;
	right: 15px;
	z-index: 9;

	max-height: 444px;
	width: 300px;

	border-radius: 8px;
	background-color: $white;
	box-shadow:
		0 0 1px 0 rgba(45, 45, 57, 0.5),
		0 8px 12px -4px rgba(45, 45, 57, 0.3);

	&__close-icon {
		position: absolute;
		top: 17px;
		right: 20px;
		cursor: pointer;
	}

	&__clear-all {
		margin-top: -1px;
		margin-right: 8px;
		padding: 0 8px !important;
		height: 20px !important;
		font-size: 14px !important;
	}

	&__header {
		padding-top: 12px;
		padding-bottom: 1px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__label {
		display: flex;
		align-items: center;
	}

	&__label-color {
		margin-right: 8px;
		width: 16px;
		min-width: 16px;
		height: 16px;
		border-radius: 4px;
	}

	&__label-name {
		width: 228px;
		padding-right: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__labels {
		max-height: 359px;
		padding: 0 8px 8px;

		&--scrollable {
			overflow-x: hidden;
			overflow-y: auto;
		}
	}

	&__no-labels {
		padding: 8px;
		@include font-base-14($n-35);
	}

	&__search {
		position: relative;
		padding: 0 8px;

		input {
			padding-left: 40px;
		}
	}

	&__search-icon {
		position: absolute;
		top: 14px;
		left: 20px;
	}

	&__title {
		padding-left: 16px;
		@include font-base-12($n-35, true);
		text-transform: uppercase;
	}

	.checkbox__label {
		padding-left: 34px;
		border-radius: 4px;

		&::before {
			left: 4px;
			background-color: $white;
		}

		&::after {
			left: 10px;
		}

		&:hover {
			background-color: $n-95;
		}
	}

	.tooltip {
		width: 200px;
	}
}
