.participant-results-machine-stats {
	padding-top: 8px;
	position: relative;
	height: 100%;

	.progress-bar {
		margin-top: 64px;
	}

	.participant-results__warning-wrapper {
		padding-bottom: 24px;
	}
}
