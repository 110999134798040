.participant-results-webrtc {
	padding-top: 8px;
	position: relative;
	height: 100%;

	.loader {
		position: relative;
		top: 50px;
		display: flex;
		justify-content: center;
	}

	.progress-bar {
		margin-top: 64px;
	}

	&__connection {
		margin-top: 24px;

		&:first-of-type {
			margin-top: 0;
		}
	}

	&__connection-table {
		margin-bottom: 24px;
		width: 100%;
		border: 1px solid $n-90;
		border-radius: 4px;
		border-spacing: 0;
		table-layout: fixed;
		word-wrap: break-word;

		tr {
			td {
				padding: 12px 16px;
				border-top: 1px solid $n-90;
				@include font-base-14($n-17);

				&.highlighted {
					font-weight: 700;
				}

				&:first-of-type {
					padding: 12px 16px;
					width: 185px;
					border-right: 1px solid $n-90;
					@include font-base-12($n-35, true);
					text-transform: uppercase;
					white-space: nowrap;
					background: $n-95;
				}
			}

			&:first-of-type td {
				border-top: none;
			}

			pre {
				font-size: 12px;
				white-space: pre-wrap;
				word-wrap: break-word;
			}
		}
	}

	@import "./toggleableTable/ToggleableTable";
}
