.navigation-menu {
	margin: 0 auto;
	padding: 0 16px;
	max-width: 1200px;

	//Overwrites
	.tab-navigation {
		background: $white;
		z-index: 999;
		margin-bottom: -1px;

		.divider--horizontal {
			max-width: 1168px;

			@include app-grid-small {
				max-width: 928px;
			}
		}
	}
}
