.labels {
	&__empty-view {
		img {
			height: 72px;
		}
		span {
			@include font-base-20($n-35, true);
		}
	}

	&__sub-header {
		padding-bottom: 32px;
		width: 100%;
		height: 64px;
		display: flex;
		justify-content: space-between;

		&-button {
			padding: 6px 12px 6px 8px;

			&:disabled {
				svg {
					path {
						fill: $n-60;
					}
				}
			}
		}

		&-search {
			position: relative;

			.form-field__label-wrapper {
				margin-bottom: 0;
			}

			input {
				padding: 7px 32px 7px 36px;
				width: 268px;
				border: 1px solid $n-75;
			}
		}

		&-close-icon {
			position: absolute;
			top: 10px;
			right: 12px;
			cursor: pointer;

			&:hover path {
				fill: $n-35;
			}
		}

		&-search-icon {
			position: absolute;
			top: 8px;
			left: 12px;
			cursor: pointer;
		}
	}

	.modal-box {
		width: 568px;
	}
}
