.convert-to-mos {
	position: absolute;
	z-index: 9;
	top: 28px;
	right: 0;
	width: 368px;
	border-radius: 8px;
	background-color: $white;
	box-shadow: 0 0 1px 0 rgba(45, 45, 57, 0.5),
		0 8px 12px -4px rgba(45, 45, 57, 0.3);

	&__alert {
		padding: 8px 8px 8px 10px;
		display: flex;
		align-items: flex-start;
		column-gap: 10px;
		@include font-base-14($y-14, true);
		background-color: $y-95;
	}

	&__alert-icon {
		path {
			fill: $y-36;
		}
	}

	&__back-button {
		padding: 4px 2px 4px 4px !important;
		width: 24px;
	}

	&__footer {
		padding: 8px 16px;
		display: flex;
		justify-content: right;
		column-gap: 8px;
	}

	&__header {
		padding: 10px 16px;
		display: flex;
		align-items: center;
		column-gap: 16px;
	}

	&__title {
		@include font-base-20($n-17, true);
	}

	.form-field {
		margin: 16px 16px 8px;
		width: 336px;
	}
}
