.usage-meter {
	padding: 6px 12px;
	margin-left: 10px;
	width: 216px;
	height: 52px;
	border: 1px solid $n-90;
	border-radius: 4px;
	background: $white;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		border-color: $n-60;
	}

	&--expired {
		background: $t-95;
		border-color: $t-95;
	}

	&__top {
		display: flex;
		justify-content: space-between;
	}

	&__plan-days-left,
	&__cu-used {
		margin-left: 4px;
		@include font-base-12($n-35);
	}

	&__plan {
		display: flex;

		&--expired {
			color: $r-28;

			.usage-meter__plan-days-left {
				font-weight: 700;
			}
		}
	}

	&__plan-name {
		text-transform: capitalize;
		@include font-base-12($n-35, true);

		span {
			text-transform: none;
			@include font-base-12($n-35);
		}
	}

	&__bottom {
		display: flex;
		@include font-base-12($n-17, true);
	}

	&__progress-bar {
		margin: 2px 0;
		height: 4px;
		width: 100%;
		background: $n-90;
		border-radius: 4px;

		&--additional {
			background: $t-17;
		}
	}

	&__progress-bar-inner {
		height: 100%;
		width: 0%;
		border-radius: 4px;
		background: $g-55;

		&--yellow {
			background: $y-55;
		}

		&--red {
			background: $r-55;
		}
	}

	&__upgrade {
		display: flex;
		align-items: center;
		@include font-base-12($t-17, true);
		text-decoration: none;
	}

	&__upgrade-icon {
		margin-right: 3px;
	}

	&__divider {
		margin: 3px 0;
		height: 1px;
		width: 100%;
		background: $n-90;
	}
}
