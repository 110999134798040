.select-project {
	width: 60%;

	&__dropdown {
		.button {
			margin-top: 32px;
		}
	}

	&__summary {
		width: 40%;
	}

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 29px;
		@include font-base-26($n-17, true);

		.button {
			border: none;
			background-color: $n-92;
			@include font-base-14($n-30, true);
		}
	}
}

.select-project-wrapper {
	display: flex;
	margin: 40px auto 0;
	max-width: 968px;
}
