.classificator-results {
	padding: 24px;
	border: 1px solid $n-90;
	border-radius: 4px;
	background-color: $white;

	&__title {
		margin-bottom: 16px;
		@include font-base-22($n-17, true);
	}

	&__filter-bar {
		display: flex;
		justify-content: space-between;
	}

	&__statuses {
		display: flex;
		align-items: center;
	}

	&__status {
		position: relative;
		margin-left: 24px;
		padding-left: 22px;
		@include font-base-14($n-17);

		&:first-of-type {
			margin-left: 0;
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			content: "";
			height: 14px;
			width: 14px;
			border-radius: 7px;
		}

		&--pass:before {
			background-color: $g-55;
		}

		&--fail:before {
			background-color: $r-55;
		}

		&--timeout:before {
			background-color: $y-55;
		}

		&--aborted:before {
			background-color: $n-75;
		}
	}

	&__chart {
		padding-top: 40px;
	}
}
