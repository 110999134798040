.modal {
	@include full-size(fixed, 0, 0, 0, 0);
	width: 100%;
	height: 100%;
	background-color: rgba(106, 109, 138, 0.3);
	overflow-y: scroll;
	z-index: 9999999;

	&__title {
		@include font-base-20($n-17, true);
	}

	&__divider {
		height: 1px;
		border-top: 1px solid $n-90;
	}

	&__container {
		padding: 16px;

		&--pull-right {
			display: flex;
			justify-content: flex-end;
		}

		&--space-between {
			display: flex;
			justify-content: space-between;
		}

		&--no-padding {
			padding: 0;
		}

		&--no-bottom-padding {
			padding-bottom: 0;
		}

		&--large-padding {
			padding: 32px 16px;
		}
	}

	&.disabled {
		display: none;
	}

	.modal-box {
		position: relative;
		left: 0%;
		margin: 16px auto 60px;
		width: 568px;
		border-radius: 12px;
		box-shadow: 0 4px 11px -6px $n-35;
		background: $white;

		.title {
			width: 100%;
			@include font-base-20($n-17, true);
		}

		.close {
			position: absolute;
			top: 24px;
			right: 24px;
			width: 18px;
			height: 18px;
			cursor: pointer;

			&:hover path {
				fill: $n-35;
			}
		}

		.action {
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: flex-end;
			justify-content: flex-end;

			&.edit {
				-webkit-justify-content: space-between;
				justify-content: space-between;
			}
		}
	}
}

.payment-card-modal {
	margin: 30px auto 60px !important;
	width: 376px !important;
}
