img.avatar {
	width: 100%;
	height: 100%;
}

.avatar-select {
	position: relative;

	.ReactCrop__crop-selection {
		border-radius: 50%;
	}

	&__preview {
		position: relative;
		width: 145px;
		height: 145px;
		border-radius: 50%;
		overflow: hidden;

		.avatar {
			width: 100%;
			height: 100%;
			border: none;

			svg {
				width: 70%;
				height: 70%;
			}

			&:hover + .avatar-select__tooltip {
				display: block;
			}
		}
	}

	&__tooltip {
		position: absolute;
		bottom: 0;
		padding-top: 3px;
		width: 100%;
		height: 27px;
		display: none;
		@include font-base-14($white, true);
		background: rgba(106, 109, 138, 0.7);
		text-align: center;
		cursor: pointer;

		&--visible,
		&:hover {
			display: block;
		}
	}

	&__actions {
		position: absolute;
		padding: 8px;
		width: 100%;
		border-radius: 4px;
		box-shadow: 0 2px 6px 0 rgba(106, 109, 138, 0.3);
		background: $white;
		transform: translateY(12px);

		&:before {
			content: "";
			position: absolute;
			top: -7px;
			left: 50%;
			margin-right: -50%;
			transform: translate(-50%, 0);
			width: 0;
			height: 0;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 8px solid $white;
		}
	}

	&__item {
		position: relative;
		margin: 0 auto;
		padding: 10px 12px;
		border-radius: 4px;
		display: block;
		@include font-base-14($n-17, true);
		letter-spacing: 0;
		text-decoration: none;
		background-color: $white;
		cursor: pointer;

		&:hover {
			background-color: $n-95;
		}

		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
		}
	}

	.divider {
		background-color: $n-90;
	}

	.modal {
		&__box {
			position: absolute;
			left: 50%;
			margin: 16px auto 60px;
			border-radius: 12px;
			box-shadow: 0 4px 11px -6px #4f4f64;
			transform: translate(-50%, 0);
			background: #ffffff;
			width: 568px;
		}

		&__crop-tool {
			display: flex;
			justify-content: center;

			.ReactCrop__image {
				width: 100%;
				height: 100%;
			}
		}
		&__action {
			display: flex;
			justify-content: flex-end;
		}

		&__cancel-button {
			margin-right: 8px;
		}

		&__title {
			padding: 16px;
		}
	}

	.ReactCrop__child-wrapper {
		max-height: 700px;
	}
}
