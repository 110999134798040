.run-result-card {
	&:last-child {
		.run-result-card__card-block-divider {
			margin-left: 8px;
			height: 108px;

			&--has-labels {
				min-height: 114px;
			}
		}

		.run-result-card__card-wrapper {
			margin-bottom: 0;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;

		&--divider {
			margin: 0 16px;
			height: 32px;
		}

		&--configure-labels {
			margin-right: 8px;
			padding: 0 7px !important;

			svg {
				margin-right: 0 !important;
				path {
					stroke: $p-48;
				}
			}
		}

		&--script-preview {
			padding: 0 7px !important;

			svg {
				margin: 0 !important;
			}
		}

		&--abort-test,
		&--view-report {
			width: 131px;
			white-space: nowrap;

			svg {
				margin-right: 10px !important;
			}
		}
	}

	&__card-block {
		display: flex;
		column-gap: 16px;
	}

	&__card-block-divider {
		margin-left: 8px;
		min-height: 108px;

		&--has-labels {
			min-height: 120px;
		}
	}

	&__card-wrapper {
		margin-top: 6px;
		padding: 12px 24px 12px 14px;
		width: 100%;
		height: 80px;
		min-width: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		border: 1px solid $n-80;
		border-radius: 8px;
		box-shadow:
			inset 0 -1px 0 0 $n-80,
			inset 0 0px 0 0,
			inset 0px 0 0 0,
			inset 0px 0 0 0;

		&--has-labels {
			height: 108px;
			margin-bottom: 6px;
		}
	}

	&__chart-icon {
		&--disabled {
			path {
				fill: $n-60;
			}
		}
	}

	&__cu,
	&__mode,
	&__participants,
	&__duration {
		display: flex;
		align-items: flex-end;
	}

	&__content {
		padding: 2px 0 2px 12px;
		height: 48px;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
	}

	&__cu--title,
	&__mode--title {
		margin-left: 4px;
		@include font-base-12($n-17);
	}

	&__cu--value,
	&__duration--value,
	&__participants--value {
		@include font-base-12($n-17, true);
	}

	&__duration--title,
	&__participants--title {
		margin-left: 4px;
		@include font-base-12($n-17);
		text-transform: lowercase;
	}

	&__label-modal-wrapper {
		position: relative;

		.assign-label-modal {
			top: 1px;
		}
	}

	&__card-actions {
		width: 100%;
		min-width: 0;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}

	&__labels {
		margin: 12px 0 4px 84px;
		width: 100%;
		display: flex;
		overflow-x: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		.label {
			margin-right: 8px;
		}
	}

	&__result-wrapper {
		display: flex;
	}

	&__round-separator {
		margin: 0 8px;
	}

	&__result-data {
		display: flex;
	}

	&__status,
	&__time {
		@include font-base-14($n-17, true);
	}

	&__status-icon {
		margin-right: 8px;
	}

	&__test-title {
		max-width: 100%;
		@include font-base-14($n-17, true);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
