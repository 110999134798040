.payment-card-form {
	&__secured {
		display: flex;
		align-items: center;
		@include font-base-14($n-35, true);

		svg {
			padding-bottom: 3px;
			margin-right: 4px;
		}
	}

	&__title {
		@include font-base-20($n-17, true);
	}

	hr {
		margin-bottom: 32px;
		border: 1px solid $n-90;
	}

	.form-label {
		margin-bottom: 0;
	}
}
