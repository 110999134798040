.payment-summary {
	padding: 24px 0;
	margin-left: 24px;
	border-radius: 8px;
	background-color: $n-95;
	@include font-base-14($n-17);
	word-wrap: break-word;

	li {
		margin-top: 15px;

		&:first-of-type {
			margin: 0;
		}
	}

	&__top {
		padding-bottom: 10px;
		padding-left: 25px;
		padding-right: 25px;

		&--divider {
			padding-bottom: 31px;
			border-bottom: 1px solid $n-90;
		}
	}

	&__base-price {
		margin-bottom: 13px;

		&--subscription {
			padding-top: 26px;
		}
	}

	&__vat-price {
		margin-bottom: 25px;
	}

	&__total-price span {
		@include font-base-16($n-17, true);
	}

	&__bottom {
		padding: 22px 25px 0;
	}

	.payment-summary__vat-price--spread,
	.payment-summary__total-price--spread,
	.payment-summary__base-price--spread {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__heading {
		margin-bottom: 14px;
		@include font-base-18($n-17, true);
	}

	&__strong {
		font-weight: 700;
	}

	&__address li {
		margin-bottom: 0;
	}
}
