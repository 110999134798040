.alert-modal {
	@include full-size(fixed, 0, 0, 0, 0);
	width: 100%;
	height: 100%;
	background-color: rgba(106, 109, 138, 0.3);
	overflow-y: scroll;
	z-index: 9999999;

	&__box {
		position: absolute;
		left: 50%;
		margin: 16px auto 60px;
		width: 568px;
		border-radius: 12px;
		box-shadow: 0 4px 12px 0 $n-75;
		display: block;
		transform: translate(-50%, 0);
		background: $white;

		.modal-header {
			margin-bottom: 25px;
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}

		.button {
			text-transform: uppercase;
		}

		&--caution {
			max-width: 440px;

			.alert-modal__message {
				@include font-base-16($n-17);
			}
		}
	}

	&__message {
		@include font-base-14($n-17);
	}
}
