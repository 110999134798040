.test-builder-create-participants {
	padding-bottom: 9px;
	width: 100%;

	&__controls {
		padding-top: 32px;
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__controls-right {
		display: flex;
		align-items: center;
	}

	&__btn {
		margin-right: 10px;
	}

	&__search {
		margin-right: 8px;
		position: relative;
		width: 258px;

		input {
			padding-left: 40px;
		}
	}

	&__search-icon {
		position: absolute;
		top: 16px;
		left: 13px;
		z-index: 9;
		cursor: pointer;
	}

	&__sort {
		width: 240px;
	}

	&__switch {
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			@include font-base-14($n-17);
		}
	}

	&__load-more {
		display: flex;
		justify-content: center;
	}

	&__no-participants {
		margin-top: 12px;
		margin-bottom: 12px;
		display: flex;
		justify-content: center;
		@include font-base-12($n-35);
	}

	// Overwrites
	.extended-pagination {
		margin-top: 19px;
	}

	.test-builder-participants__switch .switch {
		margin-bottom: 18px;
	}

	.test-builder-participants__load-more-btn {
		margin-top: 4px;
		padding: 4px 12px;
		background: transparent;
		align-self: center;

		&:disabled.button--tertiary {
			color: $n-17;
			background: transparent;
		}
	}

	.loader {
		overflow: unset;
	}
}
