.participant-results {
	min-height: 100%;

	&__back-icon {
		margin-top: 4px;
		width: 14px;
		height: 12px;
		display: flex;
	}

	&__group-title {
		margin-bottom: 10px;
		@include font-base-14($n-35);
		overflow-wrap: anywhere;
	}

	&__info-row {
		position: relative;
		border-top: none;
		border-radius: 0;
		display: flex;

		.report-card {
			width: 16.7%;

			&--long {
				width: 33.4%;
			}

			&:first-of-type {
				border-radius: 0 0 0 6px;
			}

			&:last-of-type {
				border-radius: 0 0 6px 0;
			}
		}
	}

	&__info-row--top .report-card {
		margin-top: 14px;
		border-bottom: none;

		&:first-of-type {
			border-radius: 6px 0 0 0;
		}

		&:last-of-type {
			border-radius: 0 6px 0 0;
		}
	}

	&__content {
		padding-bottom: 80px;
	}

	&__navigation {
		position: sticky;
		top: 108px;
		z-index: 9;
		margin-top: -16px;
		margin-bottom: 16px;
		padding-top: 16px;
		display: flex;
		column-gap: 8px;
		@include font-base-14($n-35, true);
		background-color: $white;
		overflow-wrap: anywhere;
		text-decoration: none;
	}

	&__tab-navigation-wrapper {
		margin-bottom: 16px;
	}

	&__title {
		margin: 0 24px 0 0;
		overflow-wrap: anywhere;
		@include font-base-24($n-17, true);
	}

	&__warning-icon {
		margin: 0 8px;
		display: flex;
	}

	&__warning-text {
		&--bold {
			font-weight: 700;
			border-bottom: 2px dotted $n-35;
		}

		&--expired {
			border-bottom: none;
		}
	}

	&__warning-beginning {
		display: flex;
		column-gap: 4px;
	}

	&__warning-wrapper {
		display: flex;
		align-items: center;
		font-size: 14px;
	}

	.tab-navigation {
		margin-top: 40px;
		margin-bottom: -1px;

		&__tab-title {
			color: $n-35;
		}

		&__tab {
			&:hover {
				.tab-navigation__tab-title {
					color: $n-17;
				}
			}

			&--active {
				.tab-navigation__tab-title {
					color: $p-48;
				}
			}

			&--disabled {
				.tab-navigation__tab-title {
					color: $n-60;
				}

				&:hover {
					.tab-navigation__tab-title {
						color: $n-60;
					}
				}
			}
		}
	}

	.tooltip__expired-text {
		text-align: center;
		p {
			@include font-base-12($white, true);
		}
	}

	@import "./artifacts/Artifacts";
	@import "./asserts/Asserts";
	@import "./logs/Logs";
	@import "./machineStats/MachineStats";
	@import "./summary/Summary";
	@import "./webRTC/WebRTC";
}
