.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__value {
		margin-left: 16px;
		width: 32px;
		@include font-base-12($n-17, true);
	}

	&__bar {
		position: relative;
		width: 320px;
		height: 5px;
		display: flex;
		justify-content: center;
		background-color: $n-75;
	}

	&__download {
		position: absolute;
		top: -1px;
		left: 0;
		height: 7px;
		background-color: $p-48;
	}

	&__text {
		margin-top: 14px;
		@include font-base-14($n-35, true);
		text-transform: uppercase;
	}
}
