.page-not-found {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&__logo-link {
		position: absolute;
		top: 40px;
		left: 40px;
	}

	&__external-link {
		padding: 12px 16px;
		margin-right: 8px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include font-base-16($white, true);
		border-radius: 4px;
		text-decoration: unset;
		transition: all 0.3s ease;
		background: $p-48;

		&:hover {
			background: $p-37;
		}
	}

	&__logo {
		width: 154px;
		height: 21px;
	}

	&__content-block {
		position: relative;
		margin: 0 auto;
		height: 80%;
		width: 90%;
		max-width: 800px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__right-col {
		flex: 1;

		@include app-grid-medium {
			margin-top: 24px;
		}

		@include app-grid-small {
			margin-top: 24px;
		}

		img {
			margin: 0 auto;
			max-height: 100%;
			max-width: 100%;
			display: block;

			@include app-grid-medium {
				width: 300px;
			}

			@include app-grid-small {
				width: 300px;
			}
		}
	}

	&__left-col {
		width: 360px;
	}

	&__sub-message {
		padding-bottom: 24px;
		@include font-base-14($n-17);
	}

	&__redirect {
		display: flex;
		justify-content: left;
		align-items: center;
		text-align: center;

		@include app-grid-small {
			justify-content: flex-start;
		}
	}

	&__message {
		padding-bottom: 24px;
		@include font-base-32($n-17, true);

		&--error {
			margin-top: 87px;
		}
	}

	&__status-code {
		padding-bottom: 8px;
		@include font-base-64($n-17, true);
	}

	.button--not-found-secondary {
		margin-left: 8px;
		color: $p-48;
		border: 1px solid $p-48;
		background: $white;

		&:hover {
			border-color: $p-37;
			color: $p-37;
			background: $white;
		}
	}
}
