.card-block {
	margin-bottom: 20px;

	&__top {
		margin-bottom: 8px;
		display: flex;
		justify-content: space-between;
	}

	&__name {
		padding-right: 10px;
		@include font-base-14($n-17, true);
		word-break: break-word;
	}

	&__status {
		@include font-base-14($n-17);
	}

	&__number {
		@include font-base-14($n-17);
	}

	&__bottom {
		display: flex;
		align-items: center;

		.card-icon {
			margin-right: 8px;
		}
	}

	&__actions {
		display: flex;

		.button--xs {
			@include font-base-14($p-48, true);
		}
	}

	&__action {
		width: max-content;
		cursor: pointer;

		&--default {
			margin-right: 4px;
		}

		&--remove {
			margin-left: 4px;
		}
	}

	&__separator {
		margin: 0 4px;
		@include font-base-14($n-17, true);
	}
}
