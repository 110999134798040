.info-text {
	position: relative;
	@include font-base-14($n-35);

	a {
		@include font-base-14($p-37, true);
		white-space: nowrap;

		&:hover {
			color: $n-17;
		}
	}

	&--updated {
		padding: 10px 40px;
		width: 100%;
		min-height: 40px;
		border-radius: 8px;
		border: none;
		background-color: $p-98;
		@include font-base-14($n-17, true);
		letter-spacing: -0.4px;

		&::before {
			@include pseudo();
			top: 8px;
			left: 8px;
			width: 24px;
			height: 24px;
			background: url("../../assets/icons/helpIcon.png") no-repeat center;
			background-size: contain;
		}

		a {
			@include font-base-14($p-48, true);
		}
	}
}
