.payments-project-upgrade {
	margin: 0 auto;
	width: 768px;
	&__title {
		margin-bottom: 29px;
		@include font-base-26($n-17, true);
	}

	.payment-plan {
		padding: 16px 32px 16px 16px;
	}
}
