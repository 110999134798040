.create-project {
	margin: 32px auto;
	padding: 0 16px;
	display: flex;
	justify-content: center;
	transition: all 0.3s ease;

	&--no-trial {
		justify-content: center;
	}

	&__form {
		width: 568px;
		min-width: 448px;
	}

	&__title {
		@include font-base-20($n-17, true);
	}

	&__input-section {
		margin-top: 24px;

		&--avatar {
			margin-top: 16px;
		}

		.form-field__label::after {
			margin-left: 4px;
		}
	}

	&__input-title {
		margin-bottom: 8px;
		@include font-base-14($n-17, true);

		&--language {
			&:after {
				content: "*";
				margin-left: 4px;
				color: $r-39;
			}
		}
	}

	&__input-desc {
		margin-bottom: 16px;
		@include font-base-12($n-35);
	}

	&__buttons-wrapper {
		margin-top: 32px;
		display: flex;
		justify-content: flex-end;
		column-gap: 16px;

		.divider {
			height: 32px;
		}
	}

	&__icons-wrapper {
		display: flex;
		align-items: center;

		img {
			width: 32px;
			height: 32px;

			&:first-child {
				margin-right: 4px;
			}
		}
	}

	&__icon-py-test-ui {
		padding: 6px;
		background-color: $y-98;
		border-radius: 4px;
		border: 1px dashed $n-80;
	}

	&__trial-banner {
		margin-left: 32px;
		padding: 16px;
		width: 100%;
		height: 224px;
		max-width: 368px;
		border: 1px solid $n-90;
		border-radius: 8px;
		background-color: $y-98;
		background-image: url("../../assets/images/trialBannerShapes.png");
		background-size: 550px 550px;
		background-position: 30px -120px;
		flex-shrink: 0;
		transition: all 0.3s ease;

		@include app-grid-small {
			width: 288px;
		}
	}

	&__trial-banner-title {
		margin-bottom: 12px;
		@include font-base-20($n-17, true);
	}

	&__trial-banner-item {
		margin-top: 8px;
		display: flex;
		align-items: flex-start;
		@include font-base-14($n-17);

		svg {
			margin-right: 8px;
			flex-shrink: 0;
		}

		span {
			border-bottom: 1px dashed $n-35;
		}

		&:first-child {
			margin-top: 0;
			font-weight: bold;
		}
	}

	&__error-message {
		margin-top: 8px;
		display: flex;
		align-items: center;
		@include font-base-14($r-39, true);
	}

	//Overwrites
	.radio-group__button {
		padding-left: 4px;
		height: 40px;
		border: 1px solid $n-75;
		border-radius: 4px;

		&:hover {
			background-color: $y-98;
		}

		&--active {
			background-color: $n-90;

			&:hover {
				background-color: $n-90;
			}
		}

		svg {
			display: none;
		}
	}
}
