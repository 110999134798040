.assert-table {
	position: relative;
	margin-bottom: 32px;
	width: 100%;
	display: block;

	&__action-row {
		margin-top: 22px;
		padding: 8px;
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		border: 1px solid $n-75;
		border-bottom: none;

		button {
			margin-left: 16px;

			&:first-of-type {
				margin-left: 0;
			}
		}
	}

	&__head {
		width: 100%;
		min-height: 40px;
		border: 1px solid $n-75;
		display: flex;
		justify-content: space-between;
		background-color: $y-98;

		.assert-table__cell {
			@include font-base-14($n-17, true);
		}

		.assert-table__check-box {
			&--active:before {
				@include pseudo();
				top: 7px;
				left: 3px;
				width: 10px;
				height: 2px;
				border-radius: 4px;
				background: $white;
			}
		}
	}

	&__body {
		width: 100%;
		min-height: 48px;
		border-left: 1px solid $n-75;
		border-right: 1px solid $n-75;
	}

	&__row {
		width: 100%;
		min-height: 48px;
		border-bottom: 1px solid $n-75;
		display: flex;
		justify-content: space-between;

		&:hover {
			background-color: $y-98;
			cursor: pointer;
		}

		&--active {
			background-color: $y-95;
		}

		.assert-table__cell--status {
			position: relative;
			padding: 0 8px 0 36px;
			text-transform: capitalize;
		}

		.assert-table__status-icon {
			&--skipped {
				top: 47%;
			}
		}

		.assert-table__cell--preconditions {
			padding: 8px 6px 8px 12px;
		}
	}

	&__precondition {
		position: relative;
		margin: 4px;
		padding: 4px 28px 4px 8px;
		min-height: 24px;
		border-radius: 16px;
		border: 1px solid $n-75;
		background-color: $n-95;
		@include font-base-14($n-17, true);
		cursor: default;

		&:hover {
			background-color: $n-90;
		}
	}

	&__delete {
		position: absolute;
		top: 9px;
		right: 8px;
		width: 12px;
		height: 12px;
		background: url("../../assets/icons/closeDark.png") no-repeat center;
		background-size: contain;
		cursor: pointer;
	}

	&__cell {
		position: relative;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 12px 10px 12px 16px;
		word-break: break-all;
		overflow: hidden;
		@include font-base-14($n-17);

		&:after {
			@include pseudo();
			left: 0;
			top: 0;
			height: 100%;
			width: 1px;
			border-right: 1px solid $n-75;
		}

		&:first-of-type::after {
			display: none;
		}

		&--select-box {
			position: relative;
			width: 48px;
		}

		&--path {
			width: 27%;
		}

		&--preconditions {
			padding: 12px 6px 12px 12px;
			width: 54%;
		}

		&--operator {
			width: 8%;
			min-width: 88px;
		}

		&--expected {
			width: 9%;
			min-width: 100px;
		}

		&--success-ratio,
		&--status {
			display: none;
		}
	}

	&__check-box {
		position: relative;
		width: 16px;
		height: 16px;
		border-radius: 2px;
		border: 1px solid $n-75;
		background: $white;
		cursor: pointer;

		&--active {
			border: none;
			background: $p-48;

			&:before {
				@include pseudo();
				top: 3px;
				left: 2px;
				width: 12px;
				height: 10px;
				background: url("../../assets/icons/passWhite.svg") no-repeat center;
				background-size: contain;
			}
		}
	}

	&__status-icon {
		position: absolute;
		top: 49%;
		left: 13px;
		transform: translate(0, -50%);
		width: 18px;
		height: 18px;
		display: block;
	}

	&--report {
		.assert-table__row:hover {
			background-color: $white;
			cursor: auto;
		}

		.assert-table__precondition {
			padding-right: 8px;

			&:hover {
				background-color: $n-95;
			}
		}

		.assert-table__delete {
			display: none;
		}

		.assert-table__cell--path {
			width: 27%;
		}

		.assert-table__cell--preconditions {
			width: 34%;
		}

		.assert-table__cell--success-ratio,
		.assert-table__cell--status {
			display: flex;
		}

		.assert-table__cell--success-ratio {
			width: 13%;
			min-width: 112px;
		}

		.assert-table__cell--status {
			width: 9%;
			min-width: 100px;
		}
	}
}
