.artifacts {
	&__artifact-card {
		padding: 8px;
		display: flex;
		justify-content: space-between;
		@include font-base-14($n-17);

		&:hover {
			background-color: $n-95;
		}

		&--expired {
			&:hover {
				background-color: $white;
				cursor: default;
			}
		}
	}

	&__artifact-card + .artifacts__artifact-card {
		border-top: 1px solid $n-90;
	}

	&__artifact-error {
		@include font-base-14($r-39, true);
	}

	&__artifact-error,
	&__artifact-name {
		padding-left: 12px;
		padding-right: 4px;
		word-break: break-all;
	}

	&__card-icon {
		width: 24px;
		display: flex;
		align-items: center;
	}

	&__left {
		padding: 4px;
		display: flex;
		align-items: center;
	}

	&__list {
		border: 1px solid $n-90;
		border-radius: 4px;
	}

	&__title {
		// TODO: make the padding 40px for all titles
		// once we introduce "Download all" button
		// or grid/list view toggle
		padding-top: 24px;
		padding-bottom: 16px;
		@include font-base-20($n-17, true);
	}

	&__wrapper + .artifacts__wrapper {
		.artifacts__title {
			padding-top: 40px;
		}
	}

	&__tooltip-wrapper {
		display: flex;
		align-items: center;
	}

	button {
		width: 32px;
		padding: 6px;

		svg {
			margin: 0;
		}
	}

	.participant-results__warning-wrapper {
		padding-top: 8px;
	}
}
